import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from "primereact/multiselect";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Message } from 'primereact/message';
import { Slider } from 'primereact/slider';
import Services from "../../service/Services";
import { CambiarFormatoFecha, isEqual } from "../Funciones";
import Cookies from "universal-cookie";
import axios from "axios";
const cookies = new Cookies();

const Block2 = ({ setProjectsProp, getClients, objectMonthlyReportDataProp, clientChangeProp, setClearDataProp, setProjectAssignmentsProp,
    clearDataProp, monthlyReportProp, projectsOfficialProp, userSelectedProp, readReportProp, listReportsUsersProp, dataUserProp,
    setProjectsUserSessionProp, dateStartRangeProp, dateEndRangeProp, loadingProp,
    setShowErrorProp, getClientProp }) => {

    const getServices = new Services()


    const [dateStart, setDateStart] = useState(null)
    const [dateEnd, setDateEnd] = useState(null)
    const [projectSessionService, setProjectSessionService] = useState([]);
    const [servicesProjects, setServicesProjects] = useState([]);
    const [servicesClientsProjectsUsers, setServicesClientsProjectsUsers] = useState([]);
    //Ejecutar servicios solo una vez
    useEffect(() => {
        const source = axios.CancelToken.source();
        getServices.getDataSpecificProject().then((data) => {
            if (data !== undefined) {
                setServicesProjects(data)
            }
        })
        getServices.getAsociacionProyectoUsuarioVigente().then(data => {
            if (data !== undefined) {
                /* console.log(data); */
                setServicesClientsProjectsUsers(data)
            }
        })
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    var projectRangeDate = []
    useEffect(() => {
        let source;
        if ((monthlyReportProp === 'monthlyReportOtherOfficial' || monthlyReportProp === 'allMonthlyReportOfOfficial') && userSelectedProp.id !== '') {
            if (readReportProp === true) {
                if (listReportsUsersProp.length !== 0) {
                    source = axios.CancelToken.source()
                    var newProjects = servicesProjects?.filter(obj1 => JSON.parse(listReportsUsersProp[0].asignaciones).some(obj2 => isEqual(obj1, obj2)));
                    let equals = [];
                    servicesProjects?.forEach(x => {
                        JSON.parse(listReportsUsersProp[0].asignaciones).forEach(y => {
                            if (parseInt(x.id) === parseInt(y.id)) {
                                equals.push({
                                    id: x.id,
                                    project: x.nombreproyecto,
                                    dateStartValidity: x.fechainiciovigencia,
                                    dateEndValidity: x.fechafinvigencia,
                                    percentage: y.percentage
                                })
                            }
                        })
                    })
                    setArrayObjectProjects(equals)

                    var newValueProject = newProjects.map(item => {
                        return {
                            id: item.id,
                            project: item.nombreproyecto,
                            dateStartValidity: item.fechainiciovigencia,
                            dateEndValidity: item.fechafinvigencia,
                        }
                    })
                    const filterDate = newValueProject.filter(x => {
                        var fechaInicio = CambiarFormatoFecha(new Date(x.dateStartValidity))
                        var fechaFin = CambiarFormatoFecha(new Date(x.dateEndValidity))
                        return ((fechaInicio <= dateStart && fechaInicio <= dateEnd
                            && fechaFin >= dateStart && fechaFin >= dateEnd) ||
                            (fechaInicio >= dateStart && fechaInicio <= dateEnd && fechaFin >= dateStart && fechaFin >= dateEnd))
                    })

                    filterDate.forEach(x => {
                        projectRangeDate.push(x)
                    })

                    setProjectAssignmentsProp(equals)
                    setSelectedProject(projectRangeDate)
                    setProjectsProp(newValueProject)
                    setItemsSelectedProject(newValueProject.length)
                    getServices.getprojectUser(userSelectedProp.id).then((data) => {
                        if (data !== undefined) {
                            setProjectSessionService(data)
                            setProjectsUserSessionProp(data)
                        }
                    })
                }
            } else {
                setSelectedProject(null)
                setArrayObjectProjects([])
                setProjectSessionService(projectsOfficialProp)
                setProjectsUserSessionProp(projectsOfficialProp)
            }
        } else if (monthlyReportProp === 'ownMonthlyReport') {
            if (readReportProp === true) {
                if (listReportsUsersProp.length !== 0) {
                    let newProjectsOwn = servicesProjects?.filter(obj1 => JSON.parse(listReportsUsersProp[0].asignaciones).some(obj2 => isEqual(obj1, obj2)));
                    let equals = [];
                    servicesProjects?.forEach(x => {
                        JSON.parse(listReportsUsersProp[0].asignaciones).forEach(y => {
                            if (parseInt(x.id) === parseInt(y.id)) {
                                equals.push({
                                    id: x.id,
                                    project: x.nombreproyecto,
                                    dateStartValidity: x.fechainiciovigencia,
                                    dateEndValidity: x.fechafinvigencia,
                                    percentage: y.percentage
                                })
                            }
                        })
                    })
                    setArrayObjectProjects(equals)
                    var newValueProjectOwn = newProjectsOwn.map(item => {
                        return {
                            id: item.id,
                            project: item.nombreproyecto,
                            dateStartValidity: item.fechainiciovigencia,
                            dateEndValidity: item.fechafinvigencia,
                        }
                    })

                    const filterDate = newValueProjectOwn.filter(x => {
                        var fechaInicio = CambiarFormatoFecha(new Date(x.dateStartValidity))
                        var fechaFin = CambiarFormatoFecha(new Date(x.dateEndValidity))
                        return ((fechaInicio <= dateStart && fechaInicio <= dateEnd
                            && fechaFin >= dateStart && fechaFin >= dateEnd) ||
                            (fechaInicio >= dateStart && fechaInicio <= dateEnd && fechaFin >= dateStart && fechaFin >= dateEnd))
                    })
                    filterDate.forEach(x => {
                        projectRangeDate.push(x)
                    })
                    setProjectAssignmentsProp(equals)
                    setSelectedProject(projectRangeDate)
                    setProjectsProp(newValueProjectOwn)
                    setItemsSelectedProject(newValueProjectOwn.length)

                    const projectsTable = {}
                    const uniqueProjects = servicesClientsProjectsUsers?.filter(function (object) {
                        return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                    })
                    setProjectSessionService(uniqueProjects)
                    setProjectsUserSessionProp(uniqueProjects)
                }
            } else if (objectMonthlyReportDataProp !== undefined) {
                if (objectMonthlyReportDataProp.asignaciones !== null) {

                    if (objectMonthlyReportDataProp.cliente.id === getClients?.id) {

                        let projects = servicesProjects?.filter(obj1 => JSON.parse(objectMonthlyReportDataProp.asignaciones).some(obj2 => isEqual(obj1, obj2)));

                        let equals = [];
                        servicesProjects?.forEach(x => {
                            JSON.parse(objectMonthlyReportDataProp.asignaciones).forEach(y => {
                                if (parseInt(x.id) === parseInt(y.id)) {
                                    equals.push({
                                        id: x.id,
                                        project: x.nombreproyecto,
                                        dateStartValidity: x.fechainiciovigencia,
                                        dateEndValidity: x.fechafinvigencia,
                                        percentage: y.percentage
                                    })
                                }
                            })
                        })
                        setArrayObjectProjects(equals)
                        var newValue = projects.map(item => {
                            return {
                                id: item.id,
                                project: item.nombreproyecto,
                                dateStartValidity: item.fechainiciovigencia,
                                dateEndValidity: item.fechafinvigencia,
                            }
                        })
                        const filterDate = newValue.filter(x => {
                            return ((x.dateStartValidity <= dateStart && x.dateStartValidity <= dateEnd
                                && x.dateEndValidity >= dateStart && x.dateEndValidity >= dateEnd) ||
                                (x.dateStartValidity >= dateStart && x.dateStartValidity <= dateEnd && x.dateEndValidity >= dateStart && x.dateEndValidity >= dateEnd))
                        })

                        filterDate.forEach(x => {
                            projectRangeDate.push(x)
                        })
                        setSelectedProject(projectRangeDate)
                        setProjectAssignmentsProp(equals)

                        setProjectsProp(newValue)
                        setItemsSelectedProject(newValue.length)

                        const projectsTable = {}
                        const uniqueProjects = servicesClientsProjectsUsers?.filter(function (object) {
                            return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                        })
                        setProjectSessionService(uniqueProjects)
                        setProjectsUserSessionProp(uniqueProjects)
                    }else{
                        setProjectAssignmentsProp([])
                    }
                }
            } else {

                const projectsTable = {}
                const uniqueProjects = servicesClientsProjectsUsers?.filter(function (object) {
                    return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                })
                setProjectSessionService(uniqueProjects)
                setProjectsUserSessionProp(uniqueProjects)
                setSelectedProject([])
                setArrayObjectProjects([])
                setProjectAssignmentsProp([])
            }
        }

        if (dateStartRangeProp !== null && dateEndRangeProp !== null) {
            setDateStart(CambiarFormatoFecha(dateStartRangeProp))
            setDateEnd(CambiarFormatoFecha(dateEndRangeProp))
        }
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [objectMonthlyReportDataProp, monthlyReportProp, userSelectedProp, projectsOfficialProp, readReportProp, listReportsUsersProp, dateStartRangeProp, dateEndRangeProp, servicesProjects, servicesClientsProjectsUsers, getClients]); // eslint-disable-line react-hooks/exhaustive-deps


    // estado para guardar el array de objetos de los proyectos seleccionados

    const [arrayObjectProjects, setArrayObjectProjects] = useState([])

    // obtener datos del select proyecto

    const [selectedProject, setSelectedProject] = useState(null)
    var projectClient = []
    const [proyectoFechaVigente, setProyectoFechaVigente] = useState([])

    const loadProjects = () => {
        if (getClients.client !== '') {
            const result = projectSessionService.filter(function (object) {
                var idClient = parseInt(object.cliente.id)
                return idClient === getClients.id
            })
            result.forEach(element => {
                var selectedProject = {}
                selectedProject.id = element.id
                selectedProject.nombre = element.nombre
                selectedProject.fechainiciovigencia = element.fechainiciovigencia
                selectedProject.fechafinvigencia = element.fechafinvigencia
                projectClient.push(selectedProject)
            });
            projectClient.sort(function (a, b) {
                var textA = a.nombre;
                var textB = b.nombre;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        }
        
        const filterDate = projectClient.filter(element => {
            return ((element.fechainiciovigencia <= dateStart && element.fechainiciovigencia <= dateEnd
                && element.fechafinvigencia >= dateStart && element.fechafinvigencia >= dateEnd) ||
                (element.fechainiciovigencia >= dateStart && element.fechainiciovigencia <= dateEnd && element.fechafinvigencia >= dateStart && element.fechafinvigencia >= dateEnd))
        }); 

        if(objectMonthlyReportDataProp !== undefined){
            let projectsAlreadyregistered = JSON.parse(objectMonthlyReportDataProp.asignaciones)

            let projectsTransformed = projectsAlreadyregistered.map(project => ({ 
                fechafinvigencia: project.dateEndValidity, 
                fechainiciovigencia: 
                project.dateStartValidity, 
                id: project.id, 
                nombre: project.project 
            }));

            projectsTransformed.forEach(transformedProject => {
                let exists = filterDate.find(project => 
                    parseInt(project.id) === parseInt(transformedProject.id)); 
                    if (!exists) { 
                        filterDate.push(transformedProject); 
                    } 
            });
        }
        setProyectoFechaVigente(filterDate)
        return proyectoFechaVigente
    }
    var dateValidError = false
    var messageDateValidError = ''

    useEffect(() => {
        if (getClients.id !== '') {
            loadProjects()
        }
    }, [getClients, dateStart, dateEnd, projectSessionService])// eslint-disable-line react-hooks/exhaustive-deps

    if (getClients.id !== '') {
        if (readReportProp === false) {
            if (proyectoFechaVigente.length === 0) {
                dateValidError = true
                messageDateValidError = 'No existe un proyecto que se encuentre en el rango de fechas del periodo seleccionado'
            } else {
                dateValidError = false
                messageDateValidError = ''
            }
        }
    }
    const [projects, setProjects] = useState([])
    useEffect(() => {
        setProjects(proyectoFechaVigente.map(item => {
            const obj = { project: item.nombre, id: parseInt(item.id), dateStartValidity: item.fechainiciovigencia, dateEndValidity: item.fechafinvigencia }
            return obj
        }))

    }, [proyectoFechaVigente])// eslint-disable-line react-hooks/exhaustive-deps

    const [itemsSelectedProject, setItemsSelectedProject] = useState(0);
    const onProjectChange = (e) => {
        if (e.value !== null) {
            var newValue = e.value.map(item => {
                return {
                    id: parseInt(item.id),
                    project: item.project,
                    percentage: 0,
                    dateStartValidity: item.dateStartValidity,
                    dateEndValidity: item.dateEndValidity
                }
            })
            setSelectedProject(e.value)
            setProjectsProp(e.value);
            setArrayObjectProjects(newValue)
            setProjectAssignmentsProp(newValue)
        } else if (e.value === null) {
            setProjectsProp([]);
        }
        if (e.value !== null) {
            setItemsSelectedProject(e.value.length)
        }

        setClearDataProp(clearDataProp + 1)
    }

    /* console.log(selectedProject) */

    // columnas para el datatable responsive

    const dt = useRef(null);

    // armar objecto con datos del usuario en sesión para llenar la tabla 

    const fullName = cookies.get('nombre')
    const Position = cookies.get('cargo')
    var sessionUserObject
    if (dataUserProp.length !== 0) {
        sessionUserObject = [{
            fullName: dataUserProp[0].nombres + ' ' + dataUserProp[0].apellidos,
            Position: dataUserProp[0].cargo.cargo
        }]
    } else {
        sessionUserObject = [{
            fullName: fullName,
            Position: Position
        }]
    }

    //Validar porcentajes

    const formatCurrency = (value) => {
        return value + '%';
    }

    const calculatePercentage = () => {
        let total = 0;
        for (let arrayObjectProject of arrayObjectProjects) {
            total += arrayObjectProject.percentage;
        }
        if (parseInt(total) !== 100) {
            setShowErrorProp(true)
            setShowError(true)
            setMenssageError('Los porcentajes no suman el 100%, distribuya nuevamente los porcentajes')
        }
        else {
            setShowErrorProp(false)
            setShowError(false)
            setMenssageError('')
        }
        return formatCurrency(total);
    }

    // componentes para la tabla lista de proyectos gestionados

    const [updatePercentage, setUpdatePercentage] = useState(arrayObjectProjects)
    const [render, setRender] = useState(0)

    const onCellEditComplete = (e) => {
        /* console.log(e) */
        setUpdatePercentage(e.props.value)
        setRender(render + 1)
        let { rowData, newValue, field, } = e;
        return rowData[field] = newValue;
    }

    const [showError, setShowError] = useState(false);
    const [menssageError, setMenssageError] = useState('');

    useEffect(() => {
        setProjectAssignmentsProp(updatePercentage)
    }, [render, updatePercentage]) // eslint-disable-line react-hooks/exhaustive-deps

    const percentageEditor = (props) => {
        return <>
            <h5 style={{ marginBottom: '13px' }}> {props.value} %</h5>
            <Slider value={props.value} onChange={(e) => {
                props.editorCallback(e.value)
            }} step={5} />
        </>
    }

    let headerGroup = <ColumnGroup>
        <Row>
            <Column header="Proyectos" field="project" rowSpan={3} />
        </Row>
        <Row>
            <Column header="Id" field="id" />
            <Column header="Porcentaje / Dedicación" field="percentage" />
        </Row>
    </ColumnGroup>;

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Total:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
            <Column footer={calculatePercentage} />
        </Row>
    </ColumnGroup>;
    const header = () => {
        return (
            <>
                <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Proyectos:</label>
                            <MultiSelect value={selectedProject} options={projects} onChange={onProjectChange} placeholder="Seleccione un proyecto"
                                className={dateValidError ? "p-invalid" : 'p-column-filter'} emptyMessage="No hay resultados" name="project" optionLabel="project" showClear
                                maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedProject} Elementos Seleccionados`} disabled={readReportProp || loadingProp} filter
                            />
                            <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <div className="datatable-responsive-demo">
                <h3 className="labels">Asignación</h3>
                <h4 className="labels" style={{ marginTop: '10px', marginBottom: '10px' }}>Colaborador:</h4>
                <div className="card">
                    <DataTable ref={dt} value={sessionUserObject} dataKey="id" className="p-datatable-responsive-demo">
                        <Column className="columna" field="fullName" header="Nombre completo" />
                        <Column className="columna" field="Position" header="Cargo " />
                    </DataTable>
                </div>
            </div>

            <div className="datatable-responsive-demo">
                <div className="card">
                    <DataTable ref={dt} value={arrayObjectProjects} header={header} className="p-datatable-responsive-demo" style={{ overflow: 'hidden' }}
                        editMode="cell" emptyMessage="No se registran datos" headerColumnGroup={headerGroup} footerColumnGroup={footerGroup}
                        loading={loadingProp}
                    >
                        <Column className="columna" headerStyle={{ width: '160px' }} field="project" header="Proyecto" />
                        <Column className="columna" headerStyle={{ width: '160px' }} field="id" header="id" />
                        <Column className="columna" headerStyle={{ width: '160px' }} field="percentage" header="Porcentaje / Dedicación"
                            editor={readReportProp ? null : (props) => percentageEditor(props)} onCellEditComplete={onCellEditComplete}
                        />

                    </DataTable>
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                <div style={{ display: 'contents' }} >
                    <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                        {
                            showError === true ?
                                <Message severity="error" text={menssageError} />
                                :
                                ""
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Block2
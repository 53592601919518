import React from "react";
import RefreshToken from './RefreshToken'

export default function withRefreshToken(WrappedComponent){
        return (props) => (
        <RefreshToken>
            <WrappedComponent {...props} />
        </RefreshToken>
    );
}

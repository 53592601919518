import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../assets/css/Forms.css'

const VerPermisos = ({ idRolProp, rolProp, permisosProp, checkedNotificationProp, checkedPlusPowerBiProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    //Obtener datos del input rol

    const [inputRol, setInputRol] = useState({
        rol: rolProp
    })


    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        setInputRol({
            rol: rolProp
        })

    }, [idRolProp, rolProp])


    //Almacenamos los valores para enviarlos por props
    const rol = inputRol.rol

    const [permisosRol, setPermisosRol] = useState([]);

    // Este estado se maneja para quen el arreglo de permisos se actualice cuando se abre la ventana o cambian los permisos
    // Se consturye el arreglo a partir de la prop permisosProp, y este almacena solo valores especificos y los deja en true y false
    useEffect(() => {
        if(displayBasic===true){
            const actualizarPermisos = async () => {
                const mostrar = JSON.parse(permisosProp);
                const nuevoPermisosRol = await mostrar.rol.map((permiso, index) => {
                    if ([2, 11, 29, 10, 12, 13, 22, 28, 30].includes(index)) {
                        if(index === 2){
                            return {
                                menu: permiso.menu,
                                consultar: permiso.consultar === "true",
                                exportarTodos: permiso.exportarTodos === "true"
                            }
                        }
                        if(index === 11){
                            return {
                                menu: permiso.menu,
                                DescargarZip: permiso.DescargarZip === "true"
                            }
                        }
                        if(index === 29){
                            return {
                                menu: permiso.menu,
                                consultar: permiso.consultar === "true"
                            }
                        }
                        if(index === 10){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                            return {
                                menu: permiso.menu,
                                consultarCalendario: permiso.consultarCalendario === "true",
                                permisos
                            }
                        }
                        if(index === 12){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                            return {
                                menu: permiso.menu,
                                ProcesosCierre: permiso.ProcesosCierre === "true",
                                permisos
                            }
                        }
                        if(index === 13){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                            return {
                                menu: permiso.menu,
                                DashBoards: permiso.DashBoards === "true",
                                permisos
                            }
                        }
                        if(index === 22){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                            return {
                                menu: permiso.menu,
                                DBPowerBI: permiso.DBPowerBI === "true",
                                permisos
                            }
                        }
                        if(index === 28){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                        
                            return {
                                menu: permiso.menu,
                                NuevoDashboards : permiso.NuevoDashboards  === "true",
                                permisos
                            }
                        }
                        if(index === 30){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                        
                            return {
                                menu: permiso.menu,
                                DBPowerBISupplier : permiso.DBPowerBISupplier  === "true",
                                permisos
                            }
                        }
                        return {
                            error: "no se encontro configuración caso especial"
                        }
                    } else {
                        const permisos = permiso.permisos.map(function(p){
                            if(p!==null && p!==false && p!=="" && p!=="false"){
                                return true
                            }
                            return false
                        });
                        return {
                            menu: permiso.menu,
                            consultar: permiso.consultar === "true",
                            permisos
                        };
                    }
                });
                setPermisosRol(nuevoPermisosRol);
            };
            actualizarPermisos();  
        }
        // eslint-disable-next-line 
    }, [displayBasic,permisosProp]);

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const dt = useRef(null);


    if(permisosRol.length!==0 && displayBasic === true){

        const User = 'Parametrizar usuario'
        const UserRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios").consultar} disabled />
        const UserCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[0]} disabled />
        const UserUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[1]} disabled />
        const UserDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[2]} disabled />
    
        const Activity = 'Parametrizar actividades'
        const ActivityRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").consultar} disabled />
        const ActivityCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[0]} disabled />
        const ActivityUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[1]} disabled />
        const ActivityDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[2]} disabled />
    
        const ExportData = 'Exportar datos'
        const ExportDataRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Exportar Datos").consultar} disabled />
        const ExportDataAll = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Exportar Datos").exportarTodos} disabled tooltip="Exportar para todos" />
    
        const Client = 'Parametrizar cliente'
        const ClientRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Clientes").consultar} disabled />
        const ClientCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[0]} disabled />
        const ClientUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[1]} disabled />
        const ClientDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[2]} disabled />
    
        const Project = 'Parametrizar proyecto'
        const ProjectRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proyectos").consultar} disabled />
        const ProjectCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[0]} disabled />
        const ProjectUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[1]} disabled />
        const ProjectDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[2]} disabled />
    
        const Aplication = 'Parametrizar aplicación'
        const AplicationRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Aplicaciones").consultar} disabled />
        const AplicationCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[0]} disabled />
        const AplicationUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[1]} disabled />
        const AplicationDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[2]} disabled />
    
        const BusinessAreas = 'Parametrizar área de negocio'
        const BusinessAreasRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").consultar} disabled />
        const BusinessAreasCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[0]} disabled />
        const BusinessAreasUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[1]} disabled />
        const BusinessAreasDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[2]} disabled />
    
        const ActivityType = 'Parametrizar tipo Actividad'
        const ActivityTypeRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").consultar} disabled />
        const ActivityTypeCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[0]} disabled />
        const ActivityTypeUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[1]} disabled />
        const ActivityTypeDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[2]} disabled />
    
        const Position = 'Parametrizar cargo'
        const PositionRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargos").consultar} disabled />
        const PositionCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[0]} disabled />
        const PositionUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[1]} disabled />
        const PositionDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[2]} disabled />
    
        const Rol = 'Parametrizar rol'
        const RolRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Roles").consultar} disabled />
        const RolCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Roles").permisos[0]} disabled />
        const RolUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Roles").permisos[1]} disabled />
        const RolDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Roles").permisos[2]} disabled />
    
        const Calendar = 'Parametrizar Calendario'
        const CalendarRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Calendario").consultarCalendario} disabled />
        const CalendarManage = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Calendario").permisos[0]} disabled tooltip="Parametrizar" />
    
        const DownloadZip = 'Descargar Zip'
        const DownloadZipRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Descargar Zip").DescargarZip} disabled tooltip="Descargar Zip" />
    
        const Closes = 'Procesos de Cierre'
        const RunClose = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Procesos Cierre").permisos[1]} disabled tooltip="Cierre Definitivo" />
        const Notification = <Checkbox checked={checkedNotificationProp} disabled tooltip="Quien lo tenga marcado recibirá mensajes semanales y mensuales" />
        const CloseOpen = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Procesos Cierre").permisos[0]} disabled tooltip="Abrir Mes" />
    
        const Historical = 'Histórico'
        const HistoricalRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Historico").consultar} disabled />
    
        const DashBoards = 'DashBoards'
        const DBActivityLogger = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards").DashBoards} disabled tooltip="Registrador de actividades" />
        const DBWorkload = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[0]} disabled tooltip="Carga de trabajo y proyectos" />
        const DBDetailTypeActivity = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[1]} disabled tooltip="Detalle tipo de actividad" />
        const DBDetailTypeActivityUser = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[2]} disabled tooltip="Detalle tipo de actividad funcionario" />
    
        const DBPowerBi = 'Dashboard Powerbi'
        const DBReadPowerBi = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").DBPowerBI} disabled />
        const DBPowerBiClient = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").permisos[1]} disabled tooltip="Dashboard de powerbi de cliente" />
        const DBPlusPowerBi = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").permisos[0]} disabled tooltip="Dashboard de powerbi con licencia" />
    
        const NoveltyAnotherOfficial = 'Novedades otro funcionario'
        const NoveltyAnotherOfficialManagement = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[3]} disabled tooltip="Gestionar novedades a otros funcionarios" />
    
        const IndividualMonthlyReport = 'Informe mensual individual'
        const IndividualMonthlyReportRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").consultar} disabled />
        const IndividualMonthlyReportReadOtherOfficial = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").permisos[0]} disabled tooltip="Consultar informe mensual otro funcionario" />
        const IndividualMonthlyReportReopen = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").permisos[1]} disabled tooltip="Reabrir informe mensual" />
    
        const InterestedPerson = 'Parametrizar personas interesadas'
        const InterestedPersonRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Personas interesadas").consultar} disabled />
        const InterestedPersonCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[0]} disabled />
        const InterestedPersonUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[1]} disabled />
        const InterestedPersonDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[2]} disabled />
    
        const Objective = 'Parametrizar objetivos'
        const ObjectiveRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos").consultar} disabled />
        const ObjectiveCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[0]} disabled />
        const ObjectiveUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[1]} disabled />
        const ObjectiveDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[2]} disabled />
    
        const Contract = 'Parametrizar contratos'
        const ContractRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").consultar} disabled />
        const ContractCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[0]} disabled />
        const ContractUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[1]} disabled />
        const ContractDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[2]} disabled />
    
        const Deliverable = 'Parametrizar entregables'
        const DeliverableRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Entregables").consultar} disabled />
        const DeliverableCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[0]} disabled />
        const DeliverableUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[1]} disabled />
        const DeliverableDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[2]} disabled />
    
        const Service = 'Parametrizar servicios'
        const ServiceRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Servicios").consultar} disabled />
        const ServiceCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[0]} disabled />
        const ServiceUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[1]} disabled />
        const ServiceDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[2]} disabled />
    
        const EquipoTrabajo = 'Parametrizar equipos de trabajo'
        const EquipoRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").consultar} disabled />
        const EquipoCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[0]} disabled />
        const EquipoUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[1]} disabled />
        const EquipoDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[2]} disabled />
    
        const TipoProyecto = 'Parametrizar tipos de proyectos'
        const TipoProyectoRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").consultar} disabled />
        const TipoProyectoCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[0]} disabled />
        const TipoProyectoUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[1]} disabled />
        const TipoProyectoDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[2]} disabled />
    
        const organizationChart = 'Organigrama'
        const organizationChartRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Organigrama").consultar} disabled />
    
        const DetailPending = 'Detalle de pendientes'
        const DetailPendingRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Detalle de Pendientes").consultar} disabled />
    
        const UserClient = 'Consultar usuarios de clientes'
        const UserClientRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios de clientes").consultar} disabled />
    
        const Supplier = 'Parametrizar proveedores'
        const SupplierRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proveedores").consultar} disabled />
        const SupplierCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[0]} disabled />
        const SupplierUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[1]} disabled />
        const SupplierDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[2]} disabled />
    
        const FilterSupplier = 'Filtros de proveedor'
        const FilterSupplierNDB1 = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards - Prov").permisos[1]} disabled tooltip="Dashboard detalle tipos de actividad con filtro de proveedor" />
        const FilterSupplierNDB2 = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards - Prov").permisos[2]} disabled tooltip="Dashboard tipos de actividad - funcionario con filtro de proveedor" />
        const FilterSupplierExportData = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Exportar datos proveedor").consultar} disabled tooltip="Exportar datos con filtro de proveedor" />
    
        const DBPowerBiFilterSupplier = 'Dashboard Powerbi con filtro de proveedor'
        const DBReadPowerBiFilterSupplier = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").DBPowerBISupplier} disabled />
        const DBPowerBiFilterSupplierClient = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").permisos[1]} disabled tooltip="Dashboard de powerbi de cliente" />
        const DBPlusPowerBiFilterSupplier = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").permisos[0]} disabled tooltip="Dashboard de powerbi con licencia" />
    
    
        const uploadImage = 'Cargar Imagen Corporativa'
        const uploadImageRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargar Imagen Corporativa").consultar} disabled tooltip="Menú de Cargar Imagen Corporativa" />
    
        //Parametrizar objetivos estrátegicos
        const strategicObjectives = 'Objetivos Estratégicos'
        const strategicObjectivesRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").consultar} disabled />
        const strategicObjectivesCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[0]} disabled />
        const strategicObjectivesUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[1]} disabled />
        const strategicObjectivesDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[2]} disabled />
       
        var TablaPermisos = [
    
            { "options": User, "read": UserRead, "create": UserCreate, "update": UserUpdate, "delete": UserDelete },
            { "options": Activity, "read": ActivityRead, "create": ActivityCreate, "update": ActivityUpdate, "delete": ActivityDelete },
            { "options": ExportData, "read": ExportDataRead, "create": ExportDataAll },
            { "options": Client, "read": ClientRead, "create": ClientCreate, "update": ClientUpdate, "delete": ClientDelete },
            { "options": Project, "read": ProjectRead, "create": ProjectCreate, "update": ProjectUpdate, "delete": ProjectDelete },
            { "options": Aplication, "read": AplicationRead, "create": AplicationCreate, "update": AplicationUpdate, "delete": AplicationDelete },
            { "options": BusinessAreas, "read": BusinessAreasRead, "create": BusinessAreasCreate, "update": BusinessAreasUpdate, "delete": BusinessAreasDelete },
            { "options": ActivityType, "read": ActivityTypeRead, "create": ActivityTypeCreate, "update": ActivityTypeUpdate, "delete": ActivityTypeDelete },
            { "options": Position, "read": PositionRead, "create": PositionCreate, "update": PositionUpdate, "delete": PositionDelete },
            { "options": Rol, "read": RolRead, "create": RolCreate, "update": RolUpdate, "delete": RolDelete },
            { "options": Calendar, "read": CalendarRead, "create": CalendarManage },
            { "options": DownloadZip, "read": DownloadZipRead },
            { "options": Closes, "read": RunClose, "update": Notification, "create": CloseOpen },
            { "options": Historical, "read": HistoricalRead },
            { "options": DashBoards, "read": DBActivityLogger, "create": DBWorkload, "update": DBDetailTypeActivity, "delete": DBDetailTypeActivityUser },
            { "options": DBPowerBi, "read": DBReadPowerBi, "create": DBPlusPowerBi, "update": DBPowerBiClient },
            { "options": NoveltyAnotherOfficial, "read": NoveltyAnotherOfficialManagement },
            { "options": IndividualMonthlyReport, "read": IndividualMonthlyReportRead, "create": IndividualMonthlyReportReadOtherOfficial, "update": IndividualMonthlyReportReopen },
            { "options": InterestedPerson, "read": InterestedPersonRead, "create": InterestedPersonCreate, "update": InterestedPersonUpdate, "delete": InterestedPersonDelete },
            { "options": Objective, "read": ObjectiveRead, "create": ObjectiveCreate, "update": ObjectiveUpdate, "delete": ObjectiveDelete },
            { "options": Contract, "read": ContractRead, "create": ContractCreate, "update": ContractUpdate, "delete": ContractDelete },
            { "options": Deliverable, "read": DeliverableRead, "create": DeliverableCreate, "update": DeliverableUpdate, "delete": DeliverableDelete },
            { "options": Service, "read": ServiceRead, "create": ServiceCreate, "update": ServiceUpdate, "delete": ServiceDelete },
            { "options": EquipoTrabajo, "read": EquipoRead, "create": EquipoCreate, "update": EquipoUpdate, "delete": EquipoDelete },
            { "options": TipoProyecto, "read": TipoProyectoRead, "create": TipoProyectoCreate, "update": TipoProyectoUpdate, "delete": TipoProyectoDelete },
            { "options": organizationChart, "read": organizationChartRead },
            { "options": DetailPending, "read": DetailPendingRead },
            { "options": UserClient, "read": UserClientRead },
            { "options": Supplier, "read": SupplierRead, "create": SupplierCreate, "update": SupplierUpdate, "delete": SupplierDelete },
            { "options": FilterSupplier, "read": FilterSupplierNDB1, "create": FilterSupplierNDB2, "update": FilterSupplierExportData },
            { "options": DBPowerBiFilterSupplier, "read": DBReadPowerBiFilterSupplier, "create": DBPlusPowerBiFilterSupplier, "update": DBPowerBiFilterSupplierClient },
            { "options": uploadImage, "read": uploadImageRead },
            { "options": strategicObjectives, "read": strategicObjectivesRead, "create": strategicObjectivesCreate, "update": strategicObjectivesUpdate, "delete": strategicObjectivesDelete },
        ]

    }

    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Permisos" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Permisos asignados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Rol</label>
                            <InputText id="rol" type="text" name="rol" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} value={rol}
                                placeholder="Registre el nombre del rol" disabled
                            />
                        </div>
                    </form>
                </div>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={TablaPermisos} className="p-datatable-responsive-demo" paginator rows={10} >
                            <Column className="p-column" field="options" header="Opciones" />
                            <Column className="p-column" field="read" header="Consultar" />
                            <Column className="p-column" field="create" header="Crear" />
                            <Column className="p-column" field="update" header="Editar" />
                            <Column className="p-column" field="delete" header="Eliminar" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default VerPermisos
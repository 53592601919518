import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/client/updateClient'
const urlCrear = process.env.REACT_APP_BASE_URL + '/v1/api/client/createClient'

const ConfirmBtn = ({ tipoDocumentoProp, numeroDocumentoProp, nombreProp, dominioProp, idClienteProp, idColaboradorProp, creadoporProp, usuarioProp,
    titulo, mensaje, accion, label, icono, clase, fechacreacionProp, showDataProp,
    tooltip, renderProp, setRenderProp, setDisplayBasicProp, updateDatailprop, objectSupplierProp,
    renderDetailProp, setRenderDetailProp, linkPowerBiProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [disabledButton, setDisabledButton] = useState(false)

    const servicio = () => {
        if (accion === "Crear") {
            crearCliente()
            setDisabledButton(true)
        } else if (accion === "Actualizar") {
            actualizarCliente()
            setDisabledButton(true)
        } else if (accion === "Eliminar") {
            eliminarCliente()
            setDisabledButton(true)
        }
    }

    const crearCliente = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                creadopor: usuarioProp,
                dominio: dominioProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                idempresa: {
                    id: 1
                },
                proveedores: [],
                numerodocumento: numeroDocumentoProp,
                razonsocial: nombreProp,
                tipodocumento: tipoDocumentoProp,
                linkpowerbi: ''
            }
        )
        /* console.log(json) */
        await axios.post(urlCrear, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                setTimeout(() => {
                    setRenderProp(renderProp + 1);
                    setDisplayConfirmation(false);
                    setDisplayBasicProp(false);
                    setDisabledButton(false)
                }, 1000);
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 406) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const actualizarCliente = async () => {
        const json = JSON.stringify(
            {
                actualizadopor: usuarioProp,
                creadopor: creadoporProp,
                dominio: dominioProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: fechacreacionProp,
                id: idClienteProp,
                idempresa: {
                    id: 1
                },
                linkpowerbi: linkPowerBiProp,
                numerodocumento: numeroDocumentoProp,
                proveedores: updateDatailprop ?
                    [
                        objectSupplierProp
                    ]
                    :
                    [],
                razonsocial: nombreProp,
                tipodocumento: tipoDocumentoProp
            }
        )
        /* console.log(json) */

        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                setTimeout(() => {
                    setRenderProp(renderProp + 1);
                    setDisplayConfirmation(false);
                    if (setDisplayBasicProp) {
                        setDisplayBasicProp(false);
                    }
                    if (renderDetailProp && setRenderDetailProp) {
                        setRenderDetailProp(renderDetailProp + 1)
                    }
                    setDisabledButton(false)
                }, 1000);
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }
    const urlEliminar = process.env.REACT_APP_BASE_URL + '/v1/api/client/delete?id=' + idClienteProp
    const eliminarCliente = async () => {
        // console.log(cliente)
        await axios.delete(urlEliminar,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                setTimeout(() => {
                    setRenderProp(renderProp + 1);
                    setDisplayConfirmation(false);
                    setDisabledButton(false)
                }, 1000);
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabledButton} />
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={clase} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltip} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <Dialog className="modalConfirm" header={titulo} visible={displayConfirmation} breakpoints={{ '960px': '75vw' }} style={{ maxWidth: '50vw' }} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                <div className="confirmation-content">
                    {showDataProp ?
                        <div className="p-fluid p-grid">
                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >Tipo documento</label>
                                <InputText value={tipoDocumentoProp} disabled />

                            </div>
                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >Número documento</label>
                                <InputText value={numeroDocumentoProp} disabled />

                            </div>
                            <div className=" p-col-12 p-md-12">
                                <label className="labelsConfirm" >Nombre / Razón social</label>
                                <InputText value={nombreProp} disabled />
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className="mensajeCofirmar">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span>{mensaje}</span>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ConfirmBtn
import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/service/update'
const urlCreate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/service/create'


const ConfirmBtn = ({ idServiceProp, idClientProp, idContractProp, serviceProp, dateStartProp, dateEndProp, statusProp, createdByProp, creationDateProp,
    dateStartServiceProp, dateEndServiceProp, otherProp, idOtherProp, OtherServiceStatusProp, idOtherServiceProp,
    setRunRenderProp, runRenderProp, setDisplayBasicProp, setShowModal, updateDetailProp, idContractServiceProp, contractServiceStatusProp, deleteDetailProp,
    noDeleteServicieProp,
    tittle, menssage, action, icon, classProp, label, tooltipProp, deleteSelectionClientProp, setDeletedClientSelectionProp}) => {



    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabledButton, setDisabledButton] = useState(false);

    const service = () => {
        if (action === "Create") {
            createService()
            setDisabledButton(true)
        } else if (action === "Update") {
            updateService()
            setDisabledButton(true)
        } else if (action === "Delete") {
            setDisabledButton(true)
            deleteService()
        }
    }

    const usuario = cookies.get('usuario')
    const createService = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                cliente: {
                    id: idClientProp
                },
                creadopor: usuario,
                descripcion: serviceProp,
                estado: statusProp,
                fechaactualizado: "",
                fechacreacion: new Date(),
            }
        )
        /* console.log(json) */
        await axios.post(urlCreate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                if (setShowModal) {
                    setShowModal(true)
                }
                if (setDisplayBasicProp) {
                    setDisplayBasicProp(false)
                }
                if (setRunRenderProp) {
                    setRunRenderProp(runRenderProp + 1)
                }
                if (deleteSelectionClientProp !== undefined) {
                    setDeletedClientSelectionProp({
                        name: '',
                        id: ''
                    })
                }
                setDisplayConfirmation(false)
                setDisabledButton(false)
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }
    const updateService = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuario,
                cliente: {
                    id: idClientProp
                },
                creadopor: createdByProp,
                descripcion: serviceProp,
                estado: statusProp,
                fechaactualizado: new Date(),
                fechacreacion: creationDateProp,
                id: idServiceProp,
            })

        /* console.log(json) */

        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    if (deleteDetailProp === false) {
                        setDisplayBasicProp(false)
                    }
                    if (updateDetailProp === false) {
                        setDisplayBasicProp(false)
                    }
                    setDisplayConfirmation(false)
                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/service/delete?id=' + idServiceProp
    const deleteService = async () => {
        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                {noDeleteServicieProp ?
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    :
                    <>
                        <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                        <Button label="Aceptar" icon="pi pi-check" onClick={() => service()} autoFocus disabled={disabledButton} />
                    </>
                }
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icon} onClick={() => onClick('displayConfirmation')} className={classProp} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={tittle} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content-modal">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{menssage}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn
import React, { useRef, useState, useEffect } from 'react'
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import AWS from 'aws-sdk'
// import AWS from 'aws-sdk/global'; // Import global AWS namespace (recommended)
import S3 from 'aws-sdk/clients/s3'; // Import only the S3 client'

const CargueDeImagen = () => {

  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState(null);
  const [disabledUpload, setDisabledUpload] = useState(false)

  //Estado para saber si subiré la imagen de la compañía o del producto
  const [uploadTypeFile, setUploadTypeFile] = useState('company');

  const onChangeUploadTypeFile = (e) => {
    setUploadTypeFile(e.value)
  }

  const allowedTypes = ['image/png'];

  const [selectedListFiles, setSelectedListFiles] = useState([])
  const onTemplateSelect = (e) => {

    const selectedFile = e.files[0];
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });
    setTotalSize(_totalSize);
    setSelectedListFiles([...selectedListFiles, selectedFile])


  };

  useEffect(() => {
    
    if (selectedListFiles.length !== 0) {
      const exist = selectedListFiles.some(e => e.type !== allowedTypes[0])

      if (exist === false) {
        setFile(selectedListFiles);
        setDisabledUpload(false)
      } else {
        setFile(null);
        toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'El formato de imagen debe ser PNG', life: 7000 });
        setDisabledUpload(true)
      }
    }

  }, [selectedListFiles])// eslint-disable-line react-hooks/exhaustive-deps


  const onTemplateUpload = async (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);

    const S3_BUCKET = process.env.REACT_APP_BUCKET_S3_UPLOAD_FILE;
    const REGION = process.env.REACT_APP_REGION_UPLOAD_FILE;


    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AKI,
      secretAccessKey: process.env.REACT_APP_SAK,
    });

    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    //Cambiar ancho y alto de la imagen seleccionada
    const resizeImage = (file) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = 173;
          canvas.height = 67;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            resolve(blob);
          }, file.type);
        };

        img.onerror = (error) => { }
      });
    };
    
    const resizedImage = await resizeImage(file[0]);

    const nameFile = uploadTypeFile === 'company' ? 'logoempresa/logo.png' : 'logomarca/logo.png'
    const params = {
      Bucket: S3_BUCKET,
      Key: nameFile,
      Body: resizedImage,
      ContentType: "image/png",
      ContentDisposition: 'inline'
    };


    try {

      await s3.putObject(params).promise();
      toast.current.show({ severity: 'info', summary: 'Satisfactorio', detail: 'Archivo Cargado', life: 10000 });
      window.location.reload()
    } catch (error) {
      
      toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'No se pudo cargar el archivo', life: 8000 });
      /* console.error(error); */
    }

  };

  const onTemplateRemove = (file, callback) => { 
    const files = selectedListFiles.filter(item =>{
      return item.size !== file.size
    })

    setSelectedListFiles(files)
    setFile(null);
    if (totalSize !== 0) {
      setTotalSize(totalSize - file.size);
    }

    callback()
  };

  const onTemplateClear = () => {
    setTotalSize(0);
    setFile(null);
    setSelectedListFiles([])
  };



  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <>

        {
          file !== null ?
            <div style={{ display: 'contents' }} >
              <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                <Message severity="info" text="El campo elegir imagen será deshabilitado cuando se escoja un archivo y se habilitará cuando este se suba o se elimine" />
              </div>
            </div>
            :
            ''
        }

        <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
          {chooseButton}
          {uploadButton}
          {cancelButton}
          <div className="flex align-items-center gap-3 ml-auto">
            <span>{formatedValue} / 1 MB</span>
            <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
          </div>
        </div>
      </>

    );
  };

  const itemTemplate = (file, props) => {

    return (

      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" tooltip='Limpiar' className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
      </div>
    );
  };


  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
      </div>
    );
  };

  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


  return (
    <>
      <Toast ref={toast}></Toast>

      <Tooltip target=".custom-choose-btn" content="Seleccionar" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Cargar" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Limpiar" position="bottom" />

      <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
        <div className="p-field p-col-12 p-md-6 p-lg-12" >
          <div className="p-field-radiobutton">
            <RadioButton inputId="company" name="company" value="company" onChange={onChangeUploadTypeFile} checked={uploadTypeFile === 'company'} />
            <label htmlFor="company" style={{ fontWeight: '100' }}>Subir imagen de la empresa</label>
          </div>

          <div className="p-field-radiobutton">
            <RadioButton inputId="product" name="product" value="product" onChange={onChangeUploadTypeFile} checked={uploadTypeFile === 'product'} />
            <label htmlFor="product" style={{ fontWeight: '100' }}>Subir imagen del producto</label>
          </div>
        </div>
      </div>

      <FileUpload ref={fileUploadRef} name="demo[]" accept="image/png" /* maxFileSize={1000000} */
        onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
        headerTemplate={headerTemplate} itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={{ icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined', style: { pointerEvents: file !== null ? 'none' : 'auto' } }}
        uploadOptions={{ icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined', style: { pointerEvents: disabledUpload ? 'none' : 'auto' } }}
        cancelOptions={cancelOptions}
        customUpload
        uploadHandler={onTemplateUpload} // Aquí se asocia el método onTemplateUpload
      />

    </>
  )
}

export default CargueDeImagen

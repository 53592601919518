import axios from "axios";
export async function UpdateValidity(urlProp, jsonProp) {
    await axios.post(urlProp, jsonProp,
        {
            headers:
            {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
           /*  console.log(response) */
        })
        .catch(function (error) {
            //console.log(error)
        })
    return {
        message: 'Actualización de vigencias Satisfactoriamente',
        loading: false
    }
}


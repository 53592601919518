import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from '../pages/Login'
import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import ConsultarUsuarios from '../pages/ConsultarUsuario'
import ConsultarClientes from '../pages/Clientes'
import ConsultarProyectos from '../pages/Proyectos'
import ConsultarAplicaciones from '../pages/Aplicaciones'
import ConsultarAreasNegocio from '../pages/AreasNegocio'
import ConsultarTiposActividad from '../pages/TiposActividad'
import ConsultarCargos from '../pages/Cargos'
import ConsultarActividades from '../pages/ConsultarActividades'
import ConsultarRoles from '../pages/Roles'
import ExportData from '../pages/ExportData'
import ConsultarCalendario from '../pages/ConsultarCalendario'
import ParametrizarCalendario from '../pages/ParametrizarCalendario'
import AbrirMes from '../pages/AbrirMes'
import CerrarMes from '../pages/CerrarMes'
import Historico from '../pages/Historico'
import CargaTrabajoProyecto from '../pages/DashBoards/CargaTrabajoProyecto'
import DetalleTipoActividad from '../pages/DashBoards/DetalleTipoActividad'
import TipoActividadFuncionario from '../pages/DashBoards/TipoActividadFuncionario'
import RecuperarContraseña from '../pages/RecuperarContraseña'
import RestablecerContraseña from '../pages/RestablecerContraseña'
import InformeMensualIndividual from '../pages/InformeMensualIndividual'
import PersonasInteresadas from '../pages/PersonasInteresadas'
import Objetivos from '../pages/Objetivos'
import Contratos from '../pages/Contratos'
import Entregables from '../pages/Entregables'
import equipoTrabajo from '../pages/EquipoTrabajo'
import Servicios from '../pages/Servicios'
import TiposProyectos from '../pages/TipoProyecto'
import OrganigramaEquipoTrabajo from '../pages/OrganigramaEquipoTrabajo'
import DetallePendientes from '../pages/DetallePendientes'
import UsuariosClientes from '../pages/UsuariosClientes'
import ConsultarProveedores from '../pages/Proveedores'

import NuevoDetalleTipoActividad from '../pages/NuevosDashboards/NuevoDetalleTipoActividad'
import NuevoTipoActividadFuncionario from '../pages/NuevosDashboards/NuevoTipoActividadFuncionario'

import ExportDataProveedor from '../pages/ExportDataProveedor'
import CargarImagen from '../pages/CargarImagen'
import ObjetivosEstrategicos from '../pages/ObjetivosEstrategicos'
import withRefreshToken from './RefreshToken/withRefreshToken'

/// Definición de rutas para el sistema 
/// path: Url donde se carga el componente 
/// exact: Busca exactamente la ruta que este en path
/// componet: Carga el componente importado 

function Router() {
    
    return (

        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/registroActividades" component={Login} />
                <Route exact path="/registroActividades/inicio" component={withRefreshToken(Home)} />
                <Route exact path="/registroActividades/consultarUsuarios" component={withRefreshToken(ConsultarUsuarios)} />
                <Route exact path="/registroActividades/clientes" component={withRefreshToken(ConsultarClientes)} />
                <Route exact path="/registroActividades/proyectos" component={withRefreshToken(ConsultarProyectos)} />
                <Route exact path="/registroActividades/aplicaciones" component={withRefreshToken(ConsultarAplicaciones)} />
                <Route exact path="/registroActividades/areasNegocio" component={withRefreshToken(ConsultarAreasNegocio)} />
                <Route exact path="/registroActividades/exportarDatos" component={withRefreshToken(ExportData)} />
                <Route exact path="/registroActividades/tiposActividades" component={withRefreshToken(ConsultarTiposActividad)} />
                <Route exact path="/registroActividades/cargos" component={withRefreshToken(ConsultarCargos)} />
                <Route exact path="/registroActividades/consultarActividades/:indicador" component={withRefreshToken(ConsultarActividades)} />
                <Route exact path="/registroActividades/roles" component={withRefreshToken(ConsultarRoles)} />
                <Route exact path="/registroActividades/consultarCalendario" component={withRefreshToken(ConsultarCalendario)} />
                <Route exact path="/registroActividades/parametrizarCalendario" component={withRefreshToken(ParametrizarCalendario)} />
                <Route exact path="/registroActividades/abrirMes" component={withRefreshToken(AbrirMes)} />
                <Route exact path="/registroActividades/cerrarMes" component={withRefreshToken(CerrarMes)} />
                <Route exact path="/registroActividades/historico" component={withRefreshToken(Historico)} />
                <Route exact path="/registroActividades/SobrecargaTrabajoProyecto" component={withRefreshToken(CargaTrabajoProyecto)} />
                <Route exact path="/registroActividades/detalleTipoActividad" component={withRefreshToken(DetalleTipoActividad)} />
                <Route exact path="/registroActividades/tipoActividadFuncionario" component={withRefreshToken(TipoActividadFuncionario)} />
                <Route exact path="/registroActividades/detalleTipoActividadProveedor" component={withRefreshToken(NuevoDetalleTipoActividad)} />
                <Route exact path="/registroActividades/tipoActividadFuncionarioProveedor" component={withRefreshToken(NuevoTipoActividadFuncionario)} />
                <Route exact path="/registroActividades/informeMensualIndividual" component={withRefreshToken(InformeMensualIndividual)} />
                <Route exact path="/registroActividades/personasInteresadas" component={withRefreshToken(PersonasInteresadas)} />
                <Route exact path="/registroActividades/objetivos" component={withRefreshToken(Objetivos)} />
                <Route exact path="/registroActividades/contratos" component={withRefreshToken(Contratos)} />
                <Route exact path="/registroActividades/entregables" component={withRefreshToken(Entregables)} />
                <Route exact path= "/registroActividades/equipoTrabajo" component={withRefreshToken(equipoTrabajo)}/>
                <Route exact path="/registroActividades/servicios" component={withRefreshToken(Servicios)} />
                <Route exact path="/registroActividades/recuperarContrasena" component={RecuperarContraseña} />
                <Route exact path="/registroActividades/restablecerContrasena" component={RestablecerContraseña} />
                <Route exact path="/registroActividades/tiposProyectos" component={withRefreshToken(TiposProyectos)} />
                <Route exact path="/registroActividades/Organigrama" component={withRefreshToken(OrganigramaEquipoTrabajo)} />
                <Route exact path="/registroActividades/detallePendientes" component={withRefreshToken(DetallePendientes)} />
                <Route exact path="/registroActividades/usuariosClientes" component={withRefreshToken(UsuariosClientes)} />
                <Route exact path="/registroActividades/proveedores" component={withRefreshToken(ConsultarProveedores)} />
                <Route exact path="/registroActividades/exportarDatosProveedor" component={withRefreshToken(ExportDataProveedor)} />
                <Route exact path="/registroActividades/cargarImagenCorporativa" component={withRefreshToken(CargarImagen)} />
                <Route exact path="/registroActividades/objetivosEstrategicos" component={withRefreshToken(ObjetivosEstrategicos)} />
                <Route exact path="/singOff" component={Login} />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

export default Router
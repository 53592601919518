import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { Slider } from "primereact/slider";
import { AutoComplete } from "primereact/autocomplete";
import { Tooltip } from "primereact/tooltip";
import { addLocale } from "primereact/api";
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import { CambiarFormatoFecha } from "../Funciones";
import Cookies from 'universal-cookie'
import axios from "axios";
const cookies = new Cookies()

const BtnCrearObjetivo = ({ idProjectProp, projectProp, getClientsProp, dateStartValidityProp, dateEndValidityProp, individualMonthReportProp, setRunRenderProp,
    runRenderProp, reloadObjectiveProp, setReloadObjectiveProp, objectivesProp, runRenderBlockProp, setRunRenderBlockProp, projectsUserSessionProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp, dataObjetiveProp }) => {

    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const [clientsUserSession, setClientsUserSession] = useState([]);
    const [objectives, setObjectives] = useState([])

    const getService = new Services()

    // buscar persona objetivo

    const [validateChange, setValidateChange] = useState(false)
    const [selectedObjectiveComplete, setSelectedObjectiveComplete] = useState(null);
    const [filteredObjective, setFilteredObjective] = useState(null)

    const searchObjective = (event) => {
        setTimeout(() => {
            let _filteredObjective;
            if (!event.query.trim().length) {
                _filteredObjective = [...objectives];
            } else {
                _filteredObjective = objectives.filter((object) => {
                    return (object.descripcion.toLowerCase().startsWith(event.query.toLowerCase()) || object.id.toString().startsWith(event.query.toLowerCase()));
                });
            }

            setFilteredObjective(_filteredObjective);
        }, 250);
    }

    const onObjectiveCompleteChange = (e) => {
        setSelectedObjectiveComplete(e.value)
        setSelectedClient({
            id: '',
            neme: '',
        })
        setSelectedProject({
            id: '',
            neme: '',
            dateStartValidity: '',
            dateEndValidity: ''
        })
        setValidateChange(true)
    }

    // Validaciones para asignar nuevo proyecto a objetivo existenete

    var assingnedNewProjectObjective = false

    if (typeof selectedObjectiveComplete === 'string' || selectedObjectiveComplete === null) {
        assingnedNewProjectObjective = false
    } else if (typeof selectedObjectiveComplete === 'object') {
        assingnedNewProjectObjective = true
    }

    const [assignedProjects, setAssingnedProjects] = useState([])
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source()
        if (displayBasic === true) {
            if (selectedObjectiveComplete !== null && typeof selectedObjectiveComplete !== 'string') {
                if (typeof selectedObjectiveComplete === 'object') {
                    var arrProject = []
                    var arrAssinged = []
                    selectedObjectiveComplete.proyectos.forEach(element => {
                        arrProject.push({
                            nombre: element.proyecto.nombreproyecto,
                            id: String(element.proyecto.id),
                            cliente: {
                                id: String(element.proyecto.cliente.id),
                                nombre: element.proyecto.cliente.razonsocial
                            },
                            dateStartValidity: element.fechainiciovigencia,
                            dateEndValidity: element.fechafinvigencia
                        })
                        arrAssinged.push({
                            name: element.proyecto.nombreproyecto,
                            id: String(element.proyecto.id),
                            cliente: {
                                id: String(element.proyecto.cliente.id),
                                nombre: element.proyecto.cliente.razonsocial
                            },
                            dateStartValidity: element.fechainiciovigencia,
                            dateEndValidity: element.fechafinvigencia
                        })
                    })
                    setAssingnedProjects(arrAssinged)
                    if (individualMonthReportProp === true) {
                        projectsUserSessionProp.forEach(element => {
                            arrProject.push(element)
                        })
                        const projectsTable = {}
                        setProjectsUserSession(arrProject.filter(function (object) {
                            return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                        }))
                        setSelectedProject({
                            id: idProjectProp,
                            name: projectProp,
                            dateStartValidity: dateStartValidityProp,
                            dateEndValidity: dateEndValidityProp
                        })
                    } else {
                        getService.getProyectosUsuarios().then(data => {
                            if (data !== undefined) {

                                data.forEach(element => {
                                    arrProject.push(element)
                                })
                                const projectsTable = {}
                                setProjectsUserSession(arrProject.filter(function (object) {
                                    return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                                }))
                                setSelectedProject({
                                    id: idProjectProp,
                                    name: projectProp,
                                    dateStartValidity: dateStartValidityProp,
                                    dateEndValidity: dateEndValidityProp
                                })
                            }
                        })
                    }
                }
            } else {
                setAssingnedProjects([])
            }

            if (individualMonthReportProp === true) {
                setSelectedClient({
                    id: getClientsProp.id,
                    name: getClientsProp.client
                })
                setClientsUserSession([{
                    id: getClientsProp.id,
                    name: getClientsProp.client
                }])
                setSelectedProject({
                    id: idProjectProp,
                    name: projectProp,
                    dateStartValidity: dateStartValidityProp,
                    dateEndValidity: dateEndValidityProp
                })
                const projectsTable = {}
                setProjectsUserSession(projectsUserSessionProp.filter(function (object) {
                    return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                }))
            } else {
                getService.getProyectosUsuarios().then(data => {
                    if (data !== undefined) {
                        var nameClients = []
                        data.forEach(element => {
                            var clients = {}
                            clients.id = element.cliente.id
                            clients.name = element.cliente.nombre
                            nameClients.push(clients)
                        })
                        const clientsTable = {}
                        const uniqueClients = nameClients.filter(function (object) {
                            return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                        })
                        setClientsUserSession(uniqueClients)
                        setProjectsUserSession(data)
                    }

                })
            }

            if (individualMonthReportProp === true) {
                var arrObjectives = []
                const result = objectivesProp.map(item => {
                    return item.proyectos.map(j => {
                        var boolean
                        if (j.proyecto.id === parseInt(idProjectProp)) {
                            boolean = item
                        }
                        return boolean
                    })
                })
                result.forEach(i => {
                    i.forEach(j => {
                        if (j !== undefined) {
                            if (j.length !== 0) {
                                arrObjectives.push(j)
                            }
                        }
                    })
                })
                var newData = []
                for (var i = 0; i < objectivesProp.length; i++) {
                    var equal = false;
                    for (var j = 0; j < arrObjectives.length & !equal; j++) {
                        if (objectivesProp[i]['id'] === arrObjectives[j]['id'] &&
                            objectivesProp[i]['descripcion'] === arrObjectives[j]['descripcion'])
                            equal = true;
                    }
                    if (!equal) newData.push(objectivesProp[i]);
                }
                let getObjectivesByClient = []
                newData.forEach(element => {
                    element.proyectos.forEach(i => {
                        if (i.proyecto.cliente.id === getClientsProp.id) {
                            getObjectivesByClient.push(element)
                        }
                    })

                })
                setObjectives(getObjectivesByClient)

            } else {
                getService.getObjectives().then(data => {
                    if (data !== undefined) {
                        setObjectives(data)
                    }
                })
            }
        }

        return () => {
            if (source) {
                getService.cancelRequest()
            }
        }
    }, [selectedObjectiveComplete, objectivesProp, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        resetData()
        setRunRenderProp(runRenderProp + 1)
        if (setRunRenderBlockProp !== undefined) {
            setRunRenderBlockProp(runRenderBlockProp + 1)
        }

        dialogFuncMap[`${name}`](false);
    }

    //Obtener datos del input objetivo

    const [inputObjective, setInputObjective] = useState({
        objective: ''
    })

    const onObjectiveChange = (event) => {
        setInputObjective({
            ...inputObjective,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState({
        name: '',
        id: ''
    });

    const clients = clientsUserSession.map(item => {
        const av = { name: item.name, id: item.id }
        return av
    })

    const [itemsSelectedClient, setItemsSelectedClient] = useState(0);
    const onClientChange = (e) => {
        setItemsSelectedClient(e.value.length)
        setSelectedClient(e.value);
        setValidateChange(true)
    }

    //Obtener datos del select tipo de proyecto

    const [selectedProject, setSelectedProject] = useState({
        id: '',
        name: '',
        dateStartValidity: '',
        dateEndValidity: ''
    });
    var projectClient = []
    var proyectoFechaVigente = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const loadProjects = () => {
        if (selectedClient !== undefined) {
            const result = projectsUserSession.filter(function (object) {
                var idClient = parseInt(object.cliente.id)
                return idClient === parseInt(selectedClient.id)
            })
            result.forEach(element => {
                projectClient.push(element)
            });
            projectClient.sort(function (a, b) {
                var textA = a.name;
                var textB = b.name;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

        }

        const filterDate = projectClient.filter(element => {
            return element.fechafinvigencia >= dateV
        })
        filterDate?.forEach(element => {
            proyectoFechaVigente.push(element)
        })
        return proyectoFechaVigente
    }
    var dateValidError = false
    var messageDateValidError = ''


    if (selectedClient.id !== '') {
        loadProjects()
        if (proyectoFechaVigente.length === 0) {
            dateValidError = true
            messageDateValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
        } else {
            dateValidError = false
            messageDateValidError = ''
        }
    }
    const tableProject = {}
    const uniqueProjects = proyectoFechaVigente.filter(function (object) {
        return tableProject.hasOwnProperty(object.id) ? false : (tableProject[object.id] = true)
    })

    const projects = uniqueProjects.map(item => {
        const av = { name: item.nombre, id: parseInt(item.id), dateStartValidity: item.fechainiciovigencia, dateEndValidity: item.fechafinvigencia }
        return av
    })

    const onProjectChange = (e) => {
        setSelectedProject(e.value);
        setValidateChange(true)
        setDateStart(null)
        setDateEnd(null)
    }

    const optionDisabledProject = (e) => {
        var disabled = false
        assignedProjects.forEach(element => {
            if (element.id === e.id) {
                disabled = true
            }
        })
        return disabled
    }

    //Obtener datos del porcentaje ingresado

    const [percentage, setPercentage] = useState(0);

    const onPercentageChange = (e) => {
        setPercentage(e.value)
        if (e.value === 100) {
            setDate(new Date())
            if (dateEnd === null) {
                setDateEnd(new Date())
            }
        } else {
            setDate(null)
        }
        setValidateChange(true)
    }

    // calendar de fecha  inicio

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let date = e.value
        setDateStart(date)
        setValidateChange(true)
    }

    /// calendar de fecha fin

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let date = e.value
        setDateEnd(date)
        setValidateChange(true)
    }

    // calendar de fecha cumplimiento

    const [date, setDate] = useState(null);

    const onDateChange = (e) => {
        let date = e.value
        setDate(date)
        if (dateEnd === null) {
            setDateEnd(date)
        }
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var changes = false

    if (validateChange === true) {
        changes = true
    }

    ////validaciones proyectos

    var projectsError = false
    var menssageProjects = ''

    /// cuando ingrese un cliente 

    var disabledProject

    if (projectClient.length === 0) {
        disabledProject = true
    }

    if (individualMonthReportProp === true) {
        disabledProject = true
    } else {
        disabledProject = false
    }

    //Almacenamos los valores para enviarlos por props

    var idObjective
    var objective
    var idProyect = selectedProject.id
    var creationDate
    var createdBy

    if (assingnedNewProjectObjective === false) {
        idObjective = null
        objective = inputObjective.objective
    } else if (assingnedNewProjectObjective === true) {
        idObjective = selectedObjectiveComplete.id
        objective = selectedObjectiveComplete.descripcion
        creationDate = selectedObjectiveComplete.fechacreacion
        createdBy = selectedObjectiveComplete.creadopor
    }

    const resetData = () => {
        if (individualMonthReportProp === false) {
            setSelectedClient({
                name: '',
                id: ''
            })
            setSelectedProject({
                id: '',
                name: '',
                dateStartValidity: '',
                dateEndValidity: ''
            })
        }
        setInputObjective({
            objective: ''
        })
        setDateStart(null)
        setDateEnd(null)
        setDate(null)
        setPercentage(0)
        setSelectedObjectiveComplete(null)
        setValidateChange(false)
    }

    // Modal para el botón Back

    const [registeredObjective, setRegisteredObjective] = useState(false)

    const ModalBack = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Back = (name) => {
            if (individualMonthReportProp === true) {
                resetData()
                setDisplayBasic(false)
                dialogFuncMap[`${name}`](false)
            } else {
                if (registeredObjective === true) {
                    window.location.href = "/registroActividades/objetivos"
                } else {
                    resetData()
                    setDisplayBasic(false)
                    dialogFuncMap[`${name}`](false);
                }
            }
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const [showModal, setShowModal] = useState(false)

    const AddMoreObjetives = () => {
        const [displayBasic2, setDisplayBasic2] = useState(showModal);

        const dialogFuncMap = {
            'displayBasic2': setDisplayBasic2,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            if (individualMonthReportProp === true) {
                setRunRenderProp(runRenderProp + 1)
                setReloadObjectiveProp(reloadObjectiveProp + 1)
                setRunRenderBlockProp(runRenderBlockProp + 1)
                setDisplayBasic(false)
                setShowModal(false)
                resetData()
            } else {
                window.location.href = "/registroActividades/objetivos"
            }
        }

        const AddMore = (name) => {
            dialogFuncMap[`${name}`](false);
            setShowModal(false);
            resetData()
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} autoFocus className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => AddMore(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic2} modal style={{}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>Información registrada satisfactoriamente ¿Desea registrar otro objetivo?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    // Validaciones para asignar nuevo proyecto a objetivo existenete

    var disabledClient = false

    if (assingnedNewProjectObjective === false) {
        disabledClient = false
    } else if (assingnedNewProjectObjective === true) {
        disabledClient = true
        assingnedNewProjectObjective = true
    }

    if (individualMonthReportProp === true) {
        disabledClient = true
    } else {
        disabledClient = false
    }

    const renderFooter = (name) => {
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    registerPermission ?
                        <ConfirmBtn
                            tittle="Confirmar Registro"
                            menssage={assingnedNewProjectObjective ? "¿Estás seguro de guardar esta información?" : "¿Está seguro de Crear este objetivo?"}
                            action={assingnedNewProjectObjective ? "Update" : "Create"}
                            label={assingnedNewProjectObjective ? "Asignar" : "Guardar"}
                            icon="pi pi-plus-circle"
                            objectiveProp={objective}
                            percentageProp={percentage}
                            dateStartProp={CambiarFormatoFecha(dateStart)}
                            dateEndProp={dateEnd === null ? dateEnd : CambiarFormatoFecha(dateEnd)}
                            dateProp={date === null ? date : CambiarFormatoFecha(date)}
                            idProyectProp={idProyect}
                            idObjectiveProp={idObjective}
                            statusProp={1}
                            creationDateProp={creationDate}
                            createdByProp={createdBy}
                            assingnedNewProjectObjectiveProp={assingnedNewProjectObjective}
                            individualMonthReportProp={individualMonthReportProp}
                            selectedProjectUpdateProp={assingnedNewProjectObjective ? true : false}
                            setShowModal={setShowModal}
                            setRegisteredObjective={setRegisteredObjective}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            updateDatailProp={assingnedNewProjectObjective ? true : false}
                            setReloadObjectiveProp={setReloadObjectiveProp}
                            reloadObjectiveProp={reloadObjectiveProp}
                            deleteDetailProp={false}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            setDisplayBasicProp={assingnedNewProjectObjective ? setDisplayBasic : undefined}
                            setInputObjectiveProp={setInputObjective}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    const usuario = cookies.get('usuario');

    // validaciones

    var objectiveError = false
    var objectiveMessage = ''

    if (objective !== '') {
        if (objective.length < 10) {
            objectiveError = true
            objectiveMessage = 'La descripción del objetivo es demasiado corta'
        } else if (objective.length > 110) {
            objectiveError = true
            objectiveMessage = 'La descripción del objetivo supera el limite permitido de 110 caracteres'
        } else if (objective.length > 10 && objective.length <= 110) {
            if (objectivesProp) {
                var objectiveDuplicate = objectivesProp.filter(obj => obj.creadopor === usuario
                    && obj.descripcion.toLowerCase() === objective.toLowerCase())
                if (objectiveDuplicate.length !== 0) {
                    objectiveDuplicate.forEach(element => {
                        const resultDuplicated = element.proyectos.filter(i => {
                            return i.proyecto.id === idProjectProp
                        })
                        if (resultDuplicated.length !== 0) {
                            objectiveError = true
                            objectiveMessage = 'Ya has creado un objetivo con la misma descripción'
                        }
                    })
                }
            }
        }
    }

    var dateStartError = false
    var dateStartMessage = ''
    var dateEndError = false
    var dateEndMessage = ''

    // validar fechas dentro del rango del proyecto seleccionado

    const validateRangeDate = () => {
        var newDateStart = CambiarFormatoFecha(dateStart)
        var newDateEnd = CambiarFormatoFecha(dateEnd)

        if (newDateStart >= selectedProject.dateStartValidity && newDateStart <= selectedProject.dateEndValidity &&
            newDateEnd >= selectedProject.dateStartValidity && newDateEnd <= selectedProject.dateEndValidity) {
            dateStartError = false
            dateStartMessage = ''
        } else {
            dateStartError = true
            dateStartMessage = 'La fecha de inicio y/o fin están fuera del rango de fechas del proyecto seleccionado'
        }
    }

    const validateDateStart = () => {
        var newDateStart = CambiarFormatoFecha(dateStart)
        if (newDateStart >= selectedProject.dateStartValidity && newDateStart <= selectedProject.dateEndValidity) {
            dateStartError = false
            dateStartMessage = ''
        } else {
            dateStartError = true
            dateStartMessage = 'La fecha de inicio esta fuera del rango de fechas del proyecto seleccionado'
        }
    }

    if (dateStart !== null && dateEnd === null && selectedProject.id !== '' && dateStartError === false) {
        validateDateStart()
    }

    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia no puede ser mayor a la fecha fin de vigencia'
        } else {
            if (dateStart !== null && dateEnd !== null && selectedProject.id !== '' && dateStartError === false) {
                validateRangeDate()
            }
        }
        if (dateStartMonthlyReportProp !== undefined && dateEndMonthlyReportProp !== undefined) {
            if (CambiarFormatoFecha(dateEnd) < CambiarFormatoFecha(dateStartMonthlyReportProp)) {
                dateEndError = true
                dateEndMessage = 'La fecha fin de asociación objetivo proyecto debe estar dentro del rango del periodo seleccionado'
            }
            if (CambiarFormatoFecha(dateStart) > CambiarFormatoFecha(dateEndMonthlyReportProp)) {
                dateStartError = true
                dateStartMessage = 'La fecha inicio de asociación objetivo proyecto debe estar dentro del rango del periodo seleccionado'
            }
        }
    }

    var dateError = false
    var dateMessage = ''

    // validar fechas dentro del rango del proyecto seleccionado

    const validateDate = () => {
        if (date <= dateStart) {
            dateError = true
            dateMessage = 'La fecha de cumplimiento no puede ser igual o menor a la fecha inicio de vigencia'
        } else {
            dateError = false
            dateMessage = ''
        }
    }

    if (date !== null) {
        validateDate()
    }


    const [duplicityError, setDuplicityError] = useState(false)
    const [duplicityMessage, setDuplicityMessage] = useState('')

    useEffect(() => {
        if (inputObjective.objective !== '') {
            var getProjectsObjective
            dataObjetiveProp.forEach(element => {
                if (objective.toLowerCase() === element.descripcion?.toLowerCase()) {
                    getProjectsObjective = element
                }
            })
            if (getProjectsObjective !== undefined) {
                getProjectsObjective.proyectos.forEach(element => {
                    if (element.proyecto.id === parseInt(selectedProject.id)) {
                        var newDateStart = dateStart !== null ? CambiarFormatoFecha(dateStart) : null
                        var newDateEnd = dateEnd !== null ? CambiarFormatoFecha(dateEnd) : null
                        if (newDateStart !== null && newDateEnd !== null) {
                            if ((newDateStart <= element.fechainiciovigencia && newDateEnd <= element.fechainiciovigencia)
                                || (newDateStart >= element.fechafinvigencia && newDateEnd >= element.fechafinvigencia)) {
                                setDuplicityError(false)
                                setDuplicityMessage('')
                            }
                            else {
                                setDuplicityError(true)
                                setDuplicityMessage('El objetivo ya se encuentra creado con el mismo proyecto y está dentro del rango de fechas del ya creado.')
                            }
                        }

                    } else {
                        setDuplicityError(false)
                        setDuplicityMessage('')
                    }

                })
            } else {
                setDuplicityError(false)
                setDuplicityMessage('')
            }
        } else {
            setDuplicityError(false)
            setDuplicityMessage('')
        }

    }, [inputObjective, dataObjetiveProp, selectedProject, dateStart, dateEnd]) // eslint-disable-line react-hooks/exhaustive-deps
    // validación para registrar

    var registerPermission = false

    if (objective !== '' && objectiveError === false && dateStart !== null && dateEnd !== null &&
        dateStartError === false && selectedProject !== null && selectedProject.id !== '' &&
        dateError === false && dateEndError === false && duplicityError === false) {
        registerPermission = true
    }

    return (
        <div>
            <button style={{ marginBottom: '10px' }} className="btnAdd" onClick={() => {
                onClick('displayBasic')
                resetData()
            }}><span className="pi pi-plus Add"></span>Gestionar objetivo</button>
            <AddMoreObjetives />
            <Dialog className="modal" header={`${assingnedNewProjectObjective ? "Asignar" : "Gestionar"} Objetivo`} visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Buscar objetivo para asignar proyecto (Se puede usar tanto el ID como la descripción)

                                <Tooltip target=".custom-target-icon" autoHide={false} />
                                <i className="custom-target-icon pi pi-question-circle" style={{ marginLeft: '5px' }}
                                    data-pr-tooltip="Antes de registrar un nuevo objetivo debe buscar en la siguiente lista si este objetivo ya esta creado, si es asi podrá asignar un nuevo proyecto, si no existe el objetivo podrá crear uno nuevo"
                                    data-pr-position="right" mouseTrack mouseTrackLeft={10} data-pr-at="right+1 top" data-pr-my="left center+6"
                                    data-pr-classname="tooltip-objective"
                                ></i>
                            </label>
                            <AutoComplete value={selectedObjectiveComplete} suggestions={filteredObjective} completeMethod={searchObjective} field="descripcion"
                                dropdown filterMatchMode="startsWith" onChange={onObjectiveCompleteChange} placeholder="Buscar objetivo"
                                className="auto"
                            />
                        </div>
                        {assingnedNewProjectObjective ?
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >
                                    {individualMonthReportProp ?
                                        `Se asignará el proyecto: ${selectedProject.name} al objetivo: ${selectedObjectiveComplete.descripcion}`
                                        :
                                        `Seleccione un nuevo proyecto para asignarle al objetivo: ${selectedObjectiveComplete.descripcion}`
                                    }
                                </label>
                            </div>
                            :
                            ""
                        }
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cliente<span className="require">*</span></label>
                            <Dropdown value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                                className="p-column-filter " emptyMessage="No hay resultados" name="client" optionLabel="name" disabled={disabledClient}
                                maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`} filter
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            {assingnedNewProjectObjective ?
                                <label className="labels" >Proyecto <span className="require">*</span>
                                    {individualMonthReportProp ?
                                        ""
                                        :
                                        <>
                                            <Tooltip target=".custom-target-icon" autoHide={false} />
                                            <i className="custom-target-icon pi pi-question-circle" style={{ marginLeft: '5px' }}
                                                data-pr-tooltip="Los proyectos deshabilitados de la lista ya están asignados al objetivo"
                                                data-pr-position="right" mouseTrack mouseTrackLeft={10} data-pr-at="right+1 top" data-pr-my="left center+6"
                                                data-pr-classname="tooltip-objective"
                                            ></i>
                                        </>
                                    }
                                </label>
                                :
                                <label className="labels" >Proyecto<span className="require">*</span></label>
                            }
                            <Dropdown value={selectedProject} options={projects} onChange={onProjectChange} placeholder="Seleccione un proyecto"
                                className={projectsError || dateValidError ? "p-invalid" : ""} emptyMessage="No hay resultados" name="project" optionLabel="name"
                                disabled={disabledProject} optionDisabled={optionDisabledProject} filter
                            />
                            <p className="mensajeError">{projectsError ? menssageProjects : ""}</p>
                            <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                        </div>
                        {assingnedNewProjectObjective ?
                            ""
                            :
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Objetivo<span className="require">*</span></label>
                                <InputText id="name" type="text" name="objective" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onObjectiveChange}
                                    placeholder="Ingrese un objetivo" value={objective}
                                    className={objectiveError || duplicityError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{objectiveError ? objectiveMessage : ""}{duplicityError ? duplicityMessage : ""}</p>
                            </div>
                        }
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Inicio<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateStartError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Fin<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateEndError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dateEndError ? dateEndMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Cumplimiento</label>
                            <Calendar placeholder="Seleccione una fecha" value={date} onChange={onDateChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dateError ? dateMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Porcentaje cumplimiento</label>
                            <h5 style={{ marginBottom: '13px' }}> {percentage} %</h5>
                            <Slider value={percentage} onChange={onPercentageChange} step={5} />
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default BtnCrearObjetivo
import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
import { CambiarFormatoFecha } from '../Funciones';
import { listUsersProjects } from './listUsersProjects';
import Services from "../../service/Services";
const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/project/update'
const urlCrear = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/project'


const services = new Services();
const ConfirmBtn = ({ proyectoProp, usuarioProp, idProyectoProp, idClienteProp, creadoporProp, usuariosProp, dateStartProp, dateEndProp,
    titulo, mensaje, accion, icono, clase, label, tooltipProp, fechacreacionProp, idTipoProyectoProp, disabledProp, nombreTipoProyectoProp, oldDateEndProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [users, setUsers] = useState([]);
    useEffect(() => {
        if (accion === "Actualizar") {
            services.getUsuarios().then(res => {
                setUsers(res);
            }).catch(function (err) {
                setUsers([]);
            })
        }

    }, [accion]); // eslint-disable-line react-hooks/exhaustive-deps

    const [disabled, setDisabled] = useState(false)
    const servicio = () => {
        if (accion === "Crear") {
            crearProyecto()
            setDisabled(true);
        } else if (accion === "Actualizar") {
            actualizarProyecto()
            setDisabled(true);
        } else if (accion === "Eliminar") {
            setDisabled(true);
            eliminarProyecto()
        }
    }

    const crearProyecto = async () => {

        const newDateStart = CambiarFormatoFecha(dateStartProp)
        const newDateEnd = CambiarFormatoFecha(dateEndProp)

        const json = JSON.stringify(

            {
                actualizadopor: "",
                cliente: {
                    id: idClienteProp
                },
                creadopor: usuarioProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                fechafinvigencia: newDateEnd,
                fechainiciovigencia: newDateStart,
                id: "",
                idempresa: {
                    id: 1
                },
                nombreproyecto: proyectoProp,
                usuarios: [],
                tipoproyectos: {
                    id: idTipoProyectoProp
                }
            }

        )
        await axios.post(urlCrear, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/proyectos"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'Proyecto existente en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const actualizarProyecto = async () => {

        const newDateStart = CambiarFormatoFecha(dateStartProp)
        const newDateEnd = CambiarFormatoFecha(dateEndProp)

        const json = JSON.stringify(
            {
                actualizadopor: usuarioProp,
                cliente: {
                    id: idClienteProp
                },
                creadopor: creadoporProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: fechacreacionProp,
                fechafinvigencia: newDateEnd,
                fechainiciovigencia: newDateStart,
                id: idProyectoProp,
                idempresa: {
                    id: 1
                },
                nombreproyecto: proyectoProp,
                usuarios: usuariosProp,
                tipoproyectos: {
                    id: idTipoProyectoProp
                },
                nombretipoproyecto: nombreTipoProyectoProp
            }
        )
        /* console.log(json) */

        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                listUsersProjects(idProyectoProp, users, oldDateEndProp, newDateEnd)
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/proyectos"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'Proyecto existente en el sistema', life: 4000 });
                }
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })



    }
    const urlEliminar = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/project/delete?id=' + idProyectoProp
    const eliminarProyecto = async () => {
        await axios.delete(urlEliminar,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/proyectos"
            })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                //console.log(error.response)
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled} />
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={clase} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} disabled={disabledProp}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={titulo} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{mensaje}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn
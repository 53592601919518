import React from "react";
import { Link } from "react-router-dom";
import SidebarMenu from "../componets/Home/MenuSidebar/sidebarMenu";
import BreadCrumb from "../componets/Home/BreadCrumb"
import DataTable from "../componets/Servicios/DataTable";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Servicios = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

   const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol

    if (permisos[20].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled">Servicios</span>
    )
    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}
                />
                <div className="container">
                    <h1 >Consultar Servicios</h1>
                </div>
                <div className="tabla">
                    <DataTable
                        permissionsProp={permisos}
                        fullTableProp={false}
                        individualMonthReportProp={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default Servicios
import React, { useRef, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import ConfirmBtn from './ConfirmBtn.js'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';
const cookies = new Cookies();

const BtnActualizarRol = ({ idRolProp, rolProp, estadoProp, permisosProp, creadoporProp, fechacreacionProp, checkedNotificationProp, servicioProp }) => {

    let toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');
    const dt = useRef(null);
    const [checkedNotification, setCheckedNotification] = useState(false);
    
    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const [inputId, setInputId] = useState({
        id: idRolProp
    })


    //Obtener datos del input rol

    const [inputRol, setInputRol] = useState({
        rol: rolProp
    })
    const [validateChange, setValidateChange] = useState(false)
    const onRolChange = (event) => {

        setInputRol({
            ...inputRol,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del select tipo de estados

    var estadoR = ""
    if (estadoProp === 0) {
        estadoR = "Inactivo"
    }

    if (estadoProp === 1) {
        estadoR = "Activo"
    }

    const [selectedEstado, setSelectedEstado] = useState({
        estado: estadoR
    });

    const estados = [
        { estado: "Activo" }, //Activo
        { estado: "Inactivo" }, //Inactivo

    ];

    const onEstadoChange = (e) => {
        setSelectedEstado(e.value);
        setValidateChange(true)

    }

    //Validar si hubo algun cambio 

    var Changes = false
    if (validateChange === true) {
        Changes = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {

        setInputId({
            id: idRolProp
        })
        setInputRol({
            rol: rolProp
        })
        setSelectedEstado({
            estado: estadoR
        })

        setCheckedNotification(checkedNotificationProp)

    }, [idRolProp, estadoR, rolProp, checkedNotificationProp]) // eslint-disable-line react-hooks/exhaustive-deps


    //Almacenamos los valores para enviarlos por props

    const idRol = inputId.id
    const rol = inputRol.rol
    const estado = selectedEstado.estado
    const usuario = cookies.get('usuario')


    //Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            setInputRol({
                rol: rolProp
            })

            setValidateChange(false)

            setDisplayBasic(false)
            setErrorDuplicated(false)
            dialogFuncMap[`${name}`](false);

        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>

            </>
        );
    }

    const [permisosRol, setPermisosRol] = useState([]);

    // Este estado se maneja para quen el arreglo de permisos se actualice cuando se abre la ventana o cambian los permisos
    // Se consturye el arreglo a partir de la prop permisosProp, y este almacena solo valores especificos y los deja en true y false
    useEffect(() => {
        if(displayBasic===true){
            const actualizarPermisos = async () => {
                const mostrar = JSON.parse(permisosProp);
                const nuevoPermisosRol = await mostrar.rol.map((permiso, index) => {
                    if ([2, 11, 29, 10, 12, 13, 22, 28, 30].includes(index)) {
                        if(index === 2){
                            return {
                                menu: permiso.menu,
                                consultar: permiso.consultar === "true",
                                exportarTodos: permiso.exportarTodos === "true"
                            }
                        }
                        if(index === 11){
                            return {
                                menu: permiso.menu,
                                DescargarZip: permiso.DescargarZip === "true"
                            }
                        }
                        if(index === 29){
                            return {
                                menu: permiso.menu,
                                consultar: permiso.consultar === "true"
                            }
                        }
                        if(index === 10){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                            return {
                                menu: permiso.menu,
                                consultarCalendario: permiso.consultarCalendario === "true",
                                permisos
                            }
                        }
                        if(index === 12){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                            return {
                                menu: permiso.menu,
                                ProcesosCierre: permiso.ProcesosCierre === "true",
                                permisos
                            }
                        }
                        if(index === 13){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                            return {
                                menu: permiso.menu,
                                DashBoards: permiso.DashBoards === "true",
                                permisos
                            }
                        }
                        if(index === 22){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                            return {
                                menu: permiso.menu,
                                DBPowerBI: permiso.DBPowerBI === "true",
                                permisos
                            }
                        }
                        if(index === 28){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                        
                            return {
                                menu: permiso.menu,
                                NuevoDashboards : permiso.NuevoDashboards  === "true",
                                permisos
                            }
                        }
                        if(index === 30){
                            const permisos = permiso.permisos.map(function(p){
                                if(p!==null && p!==false && p!=="" && p!=="false"){
                                    return true
                                }
                                return false
                            });
                        
                            return {
                                menu: permiso.menu,
                                DBPowerBISupplier : permiso.DBPowerBISupplier  === "true",
                                permisos
                            }
                        }
                        return {
                            error: "no se encontro configuración caso especial"
                        }
                    } else {
                        const permisos = permiso.permisos.map(function(p){
                            if(p!==null && p!==false && p!=="" && p!=="false"){
                                return true
                            }
                            return false
                        });
                        return {
                            menu: permiso.menu,
                            consultar: permiso.consultar === "true",
                            permisos
                        };
                    }
                });
                setPermisosRol(nuevoPermisosRol);
            };
            actualizarPermisos();  
        }
        // eslint-disable-next-line 
    }, [displayBasic]);

    //Función que maneja los cambios al seleccionar/deseleccionar los checkbox de cada permiso.
    const handleChangePermiso = (menu, permiso, nuevoValor) => {
        const menusEspeciales = ["Exportar Datos", "Calendario", "Descargar Zip", "Procesos Cierre", "Historico", "Dashboards", "Dashboard PowerBI", "Dashboard PowerBI - Proveedor"];
        setPermisosRol(prevPermisos => {
            // Encuentra el objeto del menú
            const permisoObj = prevPermisos.find(p => p.menu === menu);
            if (!permisoObj) return prevPermisos;
            
            // Crea una copia inmutable del estado
            const nuevoPermisos = [...prevPermisos];
            const index = nuevoPermisos.indexOf(permisoObj);
            const nuevoPermisoObj = { ...permisoObj };

            // Maneja los menús especiales
            if (menusEspeciales.includes(menu)) {
                switch (menu) {
                    case "Exportar Datos":
                        permiso === "consultar" ? nuevoPermisoObj.consultar = nuevoValor : nuevoPermisoObj.exportarTodos = nuevoValor;                        
                        break;
                    case "Calendario":
                        permiso === "consultarCalendario" ? nuevoPermisoObj.consultarCalendario = nuevoValor : nuevoPermisoObj.permisos[permiso] = nuevoValor;
                        break;
                    case "Descargar Zip":
                        nuevoPermisoObj.DescargarZip = nuevoValor;
                        break;
                    case "Procesos Cierre":
                        permiso === "checkedNotification" ? setCheckedNotification(nuevoValor) : nuevoPermisoObj.permisos[permiso] = nuevoValor;
                        break;
                    case "Historico":
                        nuevoPermisoObj.consultar = nuevoValor;
                        break;
                    case "Dashboards":
                        permiso === "DashBoards" ? nuevoPermisoObj.DashBoards = nuevoValor : nuevoPermisoObj.permisos[permiso] = nuevoValor;
                        break;
                    case "Dashboard PowerBI":
                        permiso === "DBPowerBI" ? nuevoPermisoObj.DBPowerBI = nuevoValor : nuevoPermisoObj.permisos[permiso] = nuevoValor;
                        break;
                    case "Dashboard PowerBI - Proveedor":
                        permiso === "DBPowerBISupplier" ? nuevoPermisoObj.DBPowerBISupplier = nuevoValor : nuevoPermisoObj.permisos[permiso] = nuevoValor;
                        break;
                    default:
                        break;
                }
            } else {
                // Maneja el caso general
                permiso === "consultar" ? nuevoPermisoObj.consultar = nuevoValor : nuevoPermisoObj.permisos[permiso] = nuevoValor;
            }
            
            // Actualiza el arreglo de permisos con el objeto modificado
            nuevoPermisos[index] = nuevoPermisoObj;
            return nuevoPermisos;
        });    
        setValidateChange(true);
        //llamamos la función para cada menú, y validar si seleccionan algun permiso como editar, eliminar y crear sin tener consultar.
        checkRead(menu)
    };
    
    //Función que valida si todos los permisos han sido seleccionados, de ser asi devuelve un valor true.
    const validarAllSelected = (menu) => {
        const menusEspeciales = ["Exportar Datos", "Calendario", "Descargar Zip", "Procesos Cierre", "Historico", "Dashboards", "Dashboard PowerBI", "Dashboard PowerBI - Proveedor","Organigrama","Detalle de Pendientes","Usuarios de clientes","Cargar Imagen Corporativa","Dashboards - Prov","Informe mensual individual"];
        
        const [menuOf,position] = menu.split('*')

        if(position){
            const permisosMenu = permisosRol.find(p => p.menu === menuOf);
            return permisosMenu.permisos[position] === true
        }        
        const permisosMenu = permisosRol.find(p => p.menu === menu);
    
        if (!permisosMenu) return false;
    
        if (menusEspeciales.includes(menu)) {
            switch (menu) {
                case "Exportar Datos":
                    return permisosMenu.consultar === true && permisosMenu.exportarTodos === true;
                case "Calendario":
                    return permisosMenu.consultarCalendario === true && permisosMenu.permisos[0] === true;
                case "Descargar Zip":
                    return permisosMenu.DescargarZip === true;
                case "Procesos Cierre":
                    return permisosMenu.permisos[0] === true && permisosMenu.permisos[1] === true && checkedNotification === true;
                case "Historico":
                    return permisosMenu.consultar === true;
                case "Dashboards":
                    return permisosMenu.DashBoards === true && permisosMenu.permisos[0] === true && permisosMenu.permisos[1] === true && permisosMenu.permisos[2] === true;
                case "Dashboard PowerBI":
                    return permisosMenu.DBPowerBI === true && permisosMenu.permisos[0] === true && permisosMenu.permisos[1] === true;
                case "Dashboard PowerBI - Proveedor":
                    return permisosMenu.DBPowerBISupplier === true && permisosMenu.permisos[0] === true && permisosMenu.permisos[1] === true;
                case "Organigrama":
                    return permisosMenu.consultar === true
                case "Detalle de Pendientes":
                    return permisosMenu.consultar === true
                case "Usuarios de clientes":
                    return permisosMenu.consultar === true
                case "Cargar Imagen Corporativa":
                    return permisosMenu.consultar === true
                case "Dashboards - Prov":
                    const otherPermiso = permisosRol.find(p => p.menu === "Exportar datos proveedor")
                    return otherPermiso.consultar === true && permisosMenu.permisos[1] === true && permisosMenu.permisos[2] === true
                case "Informe mensual individual":
                    return permisosMenu.consultar === true && permisosMenu.permisos[0] === true && permisosMenu.permisos[1] === true;
                default:
                    break;
            }
        } else {
            if(menu!=="Actividades"){
                return permisosMenu.permisos.every(permiso => permiso === true) && permisosMenu.consultar === true;
            }
            return permisosMenu.permisos[0] === true && permisosMenu.permisos[1] === true && permisosMenu.permisos[2] === true && permisosMenu.consultar === true
        }
        return false;
    };

    //Función que verifica si se cambia el valor de la casilla Seleccionar todos, de ser asi cambia el valor de cada casilla.
    const handleChangeAllSelection = (menu, valor) => {
        const menusEspeciales = ["Exportar Datos", "Calendario", "Descargar Zip", "Procesos Cierre", "Historico", "Dashboards", "Dashboard PowerBI", "Dashboard PowerBI - Proveedor", "Dashboards - Prov","Informe mensual individual"];
        const [menuOf, position] = menu.split('*');
        
        if (position) {
            setPermisosRol(prevPermisos => {
                return prevPermisos.map(permisosObj => {
                    if (permisosObj.menu === menuOf) {
                        const nuevoPermisosObj = { ...permisosObj };
                        nuevoPermisosObj.permisos[position] = valor;
                        return nuevoPermisosObj;
                    }
                    return permisosObj;
                });
            });
            return;
        }
    
        setPermisosRol(prevPermisos => {
            return prevPermisos.map(permisoObj => {
                if (permisoObj.menu === menu) {
                    const nuevoPermisoObj = { ...permisoObj };
    
                    if (menusEspeciales.includes(menu)) {
                        switch (menu) {
                            case "Exportar Datos":
                                nuevoPermisoObj.consultar = valor;
                                nuevoPermisoObj.exportarTodos = valor;
                                break;
                            case "Calendario":
                                nuevoPermisoObj.consultarCalendario = valor;
                                nuevoPermisoObj.permisos[0] = valor;
                                break;
                            case "Descargar Zip":
                                nuevoPermisoObj.DescargarZip = valor;
                                break;
                            case "Procesos Cierre":
                                nuevoPermisoObj.permisos[0] = valor;
                                nuevoPermisoObj.permisos[1] = valor;
                                setCheckedNotification(valor);
                                break;
                            case "Historico":
                                nuevoPermisoObj.consultar = valor;
                                break;
                            case "Dashboards":
                                nuevoPermisoObj.DashBoards = valor;
                                nuevoPermisoObj.permisos[0] = valor;
                                nuevoPermisoObj.permisos[1] = valor;
                                nuevoPermisoObj.permisos[2] = valor;
                                break;
                            case "Dashboard PowerBI":
                                nuevoPermisoObj.DBPowerBI = valor;
                                nuevoPermisoObj.permisos[0] = valor;
                                nuevoPermisoObj.permisos[1] = valor;
                                break;
                            case "Dashboard PowerBI - Proveedor":
                                nuevoPermisoObj.DBPowerBISupplier = valor;
                                nuevoPermisoObj.permisos[0] = valor;
                                nuevoPermisoObj.permisos[1] = valor;
                                break;
                            case "Dashboards - Prov":
                                nuevoPermisoObj.permisos[1] = valor;
                                nuevoPermisoObj.permisos[2] = valor;
                                // Actualizar el menú "Exportar datos proveedor"
                                const permisoExportar = prevPermisos.find(p => p.menu === "Exportar datos proveedor");
                                if (permisoExportar) {
                                    const nuevoPermisoExportar = { ...permisoExportar };
                                    nuevoPermisoExportar.consultar = valor;
                                    const indexExportar = prevPermisos.findIndex(p => p.menu === "Exportar datos proveedor");
                                    prevPermisos[indexExportar] = nuevoPermisoExportar;
                                }
                                break;
                            case "Informe mensual individual":
                                nuevoPermisoObj.consultar = valor;
                                nuevoPermisoObj.permisos[0] = valor;
                                nuevoPermisoObj.permisos[1] = valor;
                                break
                            default:
                                break;
                        }
                    } else {
                        if (menu === "Actividades") {
                            nuevoPermisoObj.consultar = valor;
                            nuevoPermisoObj.permisos[0] = valor;
                            nuevoPermisoObj.permisos[1] = valor;
                            nuevoPermisoObj.permisos[2] = valor;
                        } else {
                            nuevoPermisoObj.consultar = valor;
                            nuevoPermisoObj.permisos = nuevoPermisoObj.permisos.map(() => valor);
                        }
                    }
                    return nuevoPermisoObj;
                }
                return permisoObj;
            });
        });
        setValidateChange(true);
    };
    
    //Se valida si se selecciona crear,editar o eliminar sin seleccionar consultar, de ser asi, selecciona consultar también
    const checkRead = (menu) => {
        const menusEspeciales = [
            "Exportar Datos", "Calendario", "Procesos Cierre", "Historico", "Dashboards", "Dashboard PowerBI", 
            "Dashboard PowerBI - Proveedor", "Organigrama", "Detalle de Pendientes", "Usuarios de clientes", 
            "Cargar Imagen Corporativa", "Dashboards - Prov","Exportar datos proveedor"
        ];
    
        setPermisosRol(prevPermisos => {
            return prevPermisos.map(permisoObj => {
                if (permisoObj.menu === menu) {
                    const nuevoPermisoObj = { ...permisoObj };
    
                    if (menusEspeciales.includes(menu)) {
                        switch (menu) {
                            case "Exportar Datos":
                                if (nuevoPermisoObj.consultar === false && nuevoPermisoObj.exportarTodos === true) nuevoPermisoObj.consultar = true;
                                break;
                            case "Calendario":
                                if (nuevoPermisoObj.consultarCalendario === false && nuevoPermisoObj.permisos[0] === true) nuevoPermisoObj.consultarCalendario = true;
                                break;                                    
                            default:
                                break;
                        }
                    } else {
                        // Maneja el caso general
                        if (nuevoPermisoObj.consultar === false && nuevoPermisoObj.permisos.some(p => p === true)) {
                            nuevoPermisoObj.consultar = true;
                        }
                    }
                    
                    return nuevoPermisoObj;
                }
                return permisoObj;
            });
        });
    };
    
    //Función que retorna true en caso de que no hayan errores, y que tampoco se dejen todas las casillas en blanco. 
    const checkFalse = (permisos) => {
        const menusEspeciales = [
            "Exportar Datos", "Calendario", "Descargar Zip", "Procesos Cierre", "Historico", "Dashboards", "Dashboard PowerBI", 
            "Dashboard PowerBI - Proveedor", "Organigrama", "Detalle de Pendientes", "Usuarios de clientes", 
            "Cargar Imagen Corporativa", "Dashboards - Prov", "Exportar datos proveedor"
        ];

        let arregloValidacion = []
    
        for (const permiso of permisos) {
            if (menusEspeciales.includes(permiso.menu)) {
                switch (permiso.menu) {
                    case "Exportar Datos":
                        if (permiso.consultar === false && permiso.exportarTodos === false) arregloValidacion.push(false);
                        if (permiso.consultar !== false || permiso.exportarTodos !== false) arregloValidacion.push(true);
                        break;
                    case "Calendario":
                        if (permiso.consultarCalendario === false && permiso.permisos[0] === false) arregloValidacion.push(false);
                        if (permiso.consultarCalendario !== false || permiso.permisos[0] !== false) arregloValidacion.push(true);
                        break;
                    case "Descargar Zip":
                        if (permiso.DescargarZip === false) arregloValidacion.push(false);
                        if (permiso.DescargarZip !== false) arregloValidacion.push(true);
                        break;
                    case "Procesos Cierre":
                        if (permiso.permisos[0] === false && permiso.permisos[1] === false && checkedNotification === false) arregloValidacion.push(false);
                        if (permiso.permisos[0] !== false || permiso.permisos[1] !== false || checkedNotification !== false) arregloValidacion.push(true);
                        break;
                    case "Historico":
                        if (permiso.consultar === false) arregloValidacion.push(false);
                        if (permiso.consultar !== false) arregloValidacion.push(true);
                        break;
                    case "Dashboards":
                        if (permiso.DashBoards === false && permiso.permisos[0] === false && permiso.permisos[1] === false && permiso.permisos[2] === false) arregloValidacion.push(false);
                        if (permiso.DashBoards !== false || permiso.permisos[0] !== false || permiso.permisos[1] !== false || permiso.permisos[2] !== false) arregloValidacion.push(true);
                        break;
                    case "Dashboard PowerBI":
                        if (permiso.DBPowerBI === false && permiso.permisos[0] === false && permiso.permisos[1] === false) arregloValidacion.push(false);
                        if (permiso.DBPowerBI !== false || permiso.permisos[0] !== false || permiso.permisos[1] !== false) arregloValidacion.push(true);
                        break;
                    case "Dashboard PowerBI - Proveedor":
                        if (permiso.DBPowerBISupplier === false && permiso.permisos[0] === false) arregloValidacion.push(false);
                        if (permiso.DBPowerBISupplier !== false || permiso.permisos[0] !== false) arregloValidacion.push(true);
                        break;
                    case "Organigrama":
                        if (permiso.consultar === false) arregloValidacion.push(false);
                        if (permiso.consultar !== false) arregloValidacion.push(true);
                        break;
                    case "Detalle de Pendientes":
                        if (permiso.consultar === false) arregloValidacion.push(false);
                        if (permiso.consultar !== false) arregloValidacion.push(true);
                        break;
                    case "Usuarios de clientes":
                        if (permiso.consultar === false) arregloValidacion.push(false);
                        if (permiso.consultar !== false) arregloValidacion.push(true);
                        break;
                    case "Cargar Imagen Corporativa":
                        if (permiso.consultar === false) arregloValidacion.push(false);
                        if (permiso.consultar !== false) arregloValidacion.push(true);
                        break;
                    case "Dashboards - Prov":
                        if (permiso.permisos[1] === false && permiso.permisos[2] === false) arregloValidacion.push(false);
                        if (permiso.permisos[1] !== false || permiso.permisos[2] !== false) arregloValidacion.push(true);
                        break;
                    case "Exportar datos proveedor":
                        if (permiso.consultar === false) arregloValidacion.push(false);
                        if (permiso.consultar !== false) arregloValidacion.push(true);
                        break;
                    default:
                        break;
                }
            } else {
                if (permiso.consultar === false && permiso.permisos.every(p => p === false)) arregloValidacion.push(false);
                if (permiso.consultar !== false || permiso.permisos.some(p => p !== false)) arregloValidacion.push(true);
            }
        }

        return !arregloValidacion.every(x => x === false) && rolError === false && rol !== '' && errorDuplicated === false && rol !== undefined
    };

    const renderFooter = (name) => {
        return (
            <div>
                {Changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {checkFalse(permisosRol) ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Actualizar"
                        icono="pi pi-pencil"
                        idRolProp={idRol}
                        usuarioProp={usuario}
                        rolProp={rol}
                        estadoProp={estado}
                        creadoporProp={creadoporProp}
                        checkedUserReadProp={permisosRol.find(p => p.menu === "Usuarios").consultar}
                        checkedUserCreateProp={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[0]}
                        checkedUserUpdateProp={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[1]}
                        checkedUserDeleteProp={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[2]}
                        checkedActivityReadProp={permisosRol.find(permiso => permiso.menu === "Actividades").consultar}
                        checkedActivityCreateProp={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[0]}
                        checkedActivityUpdateProp={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[1]}
                        checkedActivityDeleteProp={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[2]}
                        checkedExportDataReadProp={permisosRol.find(permiso => permiso.menu === "Exportar Datos").consultar}
                        checkedExportDataAllProp={permisosRol.find(permiso => permiso.menu === "Exportar Datos").exportarTodos}
                        checkedClientReadProp={permisosRol.find(permiso => permiso.menu === "Clientes").consultar}
                        checkedClientCreateProp={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[0]}
                        checkedClientUpdateProp={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[1]}
                        checkedClientDeleteProp={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[2]}
                        checkedProjectReadProp={permisosRol.find(permiso => permiso.menu === "Proyectos").consultar}
                        checkedProjectCreateProp={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[0]}
                        checkedProjectUpdateProp={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[1]}
                        checkedProjectDeleteProp={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[2]}
                        checkedAplicationReadProp={permisosRol.find(permiso => permiso.menu === "Aplicaciones").consultar}
                        checkedAplicationCreateProp={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[0]}
                        checkedAplicationUpdateProp={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[1]}
                        checkedAplicationDeleteProp={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[2]}
                        checkedBusinessAreasReadProp={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").consultar}
                        checkedBusinessAreasCreateProp={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[0]}
                        checkedBusinessAreasUpdateProp={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[1]}
                        checkedBusinessAreasDeleteProp={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[2]}
                        checkedActivityTypeReadProp={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").consultar}
                        checkedActivityTypeCreateProp={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[0]}
                        checkedActivityTypeUpdateProp={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[1]}
                        checkedActivityTypeDeleteProp={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[2]}
                        checkedPositionReadProp={permisosRol.find(permiso => permiso.menu === "Cargos").consultar}
                        checkedPositionCreateProp={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[0]}
                        checkedPositionUpdateProp={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[1]}
                        checkedPositionDeleteProp={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[2]}
                        checkedRolReadProp={permisosRol.find(permiso => permiso.menu === "Roles").consultar}
                        checkedRolCreateProp={permisosRol.find(permiso => permiso.menu === "Roles").permisos[0]}
                        checkedRolUpdateProp={permisosRol.find(permiso => permiso.menu === "Roles").permisos[1]}
                        checkedRolDeleteProp={permisosRol.find(permiso => permiso.menu === "Roles").permisos[2]}
                        checkedCalendarReadProp={permisosRol.find(permiso => permiso.menu === "Calendario").consultarCalendario}
                        checkedCalendarManageProp={permisosRol.find(permiso => permiso.menu === "Calendario").permisos[0]}
                        checkedDownloadZipProp={permisosRol.find(permiso => permiso.menu === "Descargar Zip").DescargarZip}
                        checkedRunCloseProp={permisosRol.find(permiso => permiso.menu === "Procesos Cierre").permisos[1]}
                        checkedCloseOpenProp={permisosRol.find(permiso => permiso.menu === "Procesos Cierre").permisos[0]}
                        checkedNotificationProp={checkedNotification}
                        checkedHistoricalProp={permisosRol.find(permiso => permiso.menu === "Historico").consultar}
                        checkedDBActivityLoggerProp={permisosRol.find(permiso => permiso.menu === "Dashboards").DashBoards}
                        checkedDBWorkloadProp={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[0]}
                        checkedDBDetailTypeActivityProp={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[1]}
                        checkedDBDetailTypeActivityUserProp={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[2]}
                        checkedDBReadPowerBiProp={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").DBPowerBI}
                        checkedDBPowerBiClientProp={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").permisos[1]}
                        checkedDBPlusPowerBiProp={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").permisos[0]}
                        checkedNoveltyAnotherOfficialProp={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[3]}
                        checkedindividualMonthlyReportReadProp={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").consultar}
                        checkedindividualMonthlyReportReadOtherOfficialProp={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").permisos[0]}
                        checkedindividualMonthlyReportReopenProp={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").permisos[1]}
                        checkedInterestedPersonReadProp={permisosRol.find(permiso => permiso.menu === "Personas interesadas").consultar}
                        checkedInterestedPersonCreateProp={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[0]}
                        checkedInterestedPersonUpdateProp={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[1]}
                        checkedInterestedPersonDeleteProp={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[2]}
                        fechacreacionProp={fechacreacionProp}
                        checkedObjectiveReadProp={permisosRol.find(permiso => permiso.menu === "Objetivos").consultar}
                        checkedObjectiveCreateProp={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[0]}
                        checkedObjectiveUpdateProp={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[1]}
                        checkedObjectiveDeleteProp={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[2]}
                        checkedContractReadProp={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").consultar}
                        checkedContractCreateProp={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[0]}
                        checkedContractUpdateProp={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[1]}
                        checkedContractDeleteProp={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[2]}
                        checkedDeliverableReadProp={permisosRol.find(permiso => permiso.menu === "Entregables").consultar}
                        checkedDeliverableCreateProp={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[0]}
                        checkedDeliverableUpdateProp={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[1]}
                        checkedDeliverableDeleteProp={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[2]}
                        checkedServiceReadProp={permisosRol.find(permiso => permiso.menu === "Servicios").consultar}
                        checkedServiceCreateProp={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[0]}
                        checkedServiceUpdateProp={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[1]}
                        checkedServiceDeleteProp={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[2]}
                        checkedWorkTeamReadProp={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").consultar}
                        checkedWorkTeamCreateProp={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[0]}
                        checkedWorkTeamUpdateProp={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[1]}
                        checkedWorkTeamDeleteProp={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[2]}
                        checkedTypeProjectReadProp={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").consultar}
                        checkedTypeProjectCreateProp={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[0]}
                        checkedTypeProjectUpdateProp={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[1]}
                        checkedTypeProjectDeleteProp={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[2]}
                        checkedOrgReadProp={permisosRol.find(permiso => permiso.menu === "Organigrama").consultar}
                        checkedDetailPendingReadProp={permisosRol.find(permiso => permiso.menu === "Detalle de Pendientes").consultar}
                        checkedUserClientReadProp={permisosRol.find(permiso => permiso.menu === "Usuarios de clientes").consultar}
                        checkedSupplierReadProp={permisosRol.find(permiso => permiso.menu === "Proveedores").consultar}
                        checkedSupplierCreateProp={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[0]}
                        checkedSupplierUpdateProp={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[1]}
                        checkedSupplierDeleteProp={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[2]}
                        checkedFilterSupplierNDB1Prop={permisosRol.find(permiso => permiso.menu === "Dashboards - Prov").permisos[1]}
                        checkedFilterSupplierNDB2Prop={permisosRol.find(permiso => permiso.menu === "Dashboards - Prov").permisos[2]}
                        checkedFilterSupplierExportDataProp={permisosRol.find(permiso => permiso.menu === "Exportar datos proveedor").consultar}
                        checkedDBReadPowerBiFilterSupplierProp={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").DBPowerBISupplier}
                        checkedDBPowerBiFilterSupplierClientProp={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").permisos[1]}
                        checkedDBPlusPowerBiFilterSupplierProp={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").permisos[0]}
                        checkedBDReadUploadImageProp={permisosRol.find(permiso => permiso.menu === "Cargar Imagen Corporativa").consultar}
                        checkedStratetigicObjectivesReadProp={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").consultar}
                        checkedStratetigicObjectivesCreateProp={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[0]}
                        checkedStratetigicObjectivesUpdateProp={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[1]}
                        checkedStratetigicObjectivesDeleteProp={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[2]}
                    />
                    : ""
                }

            </div>
        );
    }
    //validaciones
    var rolError = false
    var mensajeRol = ''

    if (rol.length < 3 && rol !== '') {
        rolError = true
        mensajeRol = 'El nombre del rol debe ser mayor a 3 dígitos'
    } else if (rol.length > 100) {
        rolError = true
        mensajeRol = 'El nombre del rol debe ser menor a 100 dígitos'
    }

    const [errorDuplicated, setErrorDuplicated] = useState(false)

    //Validar que no se cree un rol igual a uno creado
    useEffect(() => {
        if (inputRol.rol !== rolProp) {
            let arrItemsDuplicated = []
            servicioProp.forEach(element => {
                let rolNameCreated = removeAccents(element.nombre)
                let newInputRol = removeAccents(inputRol.rol)
                if (newInputRol.toUpperCase() === rolNameCreated.toUpperCase()) {
                    arrItemsDuplicated.push(element)
                }
            })

            if (arrItemsDuplicated.length !== 0) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Rol pre-existente' });
                setErrorDuplicated(true)
            } else {
                setErrorDuplicated(false)
            }
        }
    }, [inputRol]); // eslint-disable-line react-hooks/exhaustive-deps

    if(permisosRol.length!==0 && displayBasic === true){

        const User = 'Parametrizar usuario'
        const UserRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios").consultar}  onChange={e => handleChangePermiso("Usuarios","consultar",e.checked)}/>
        const UserCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[0]}  onChange={e => handleChangePermiso("Usuarios",0,e.checked)}/>
        const UserUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[1]}  onChange={e => handleChangePermiso("Usuarios",1,e.checked)}/>
        const UserDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios").permisos[2]}  onChange={e => handleChangePermiso("Usuarios",2,e.checked)}/>
        const UserSelectAll = <Checkbox checked={validarAllSelected("Usuarios")} onChange={e => handleChangeAllSelection("Usuarios",e.checked)} />
    
        const Activity = 'Parametrizar actividades'
        const ActivityRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").consultar}  onChange={e => handleChangePermiso("Actividades","consultar",e.checked)}/>
        const ActivityCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[0]}  onChange={e => handleChangePermiso("Actividades",0,e.checked)}/>
        const ActivityUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[1]}  onChange={e => handleChangePermiso("Actividades",1,e.checked)}/>
        const ActivityDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[2]}  onChange={e => handleChangePermiso("Actividades",2,e.checked)}/>
        const ActivitySelectAll = <Checkbox checked={validarAllSelected("Actividades")} onChange={e => handleChangeAllSelection("Actividades",e.checked)} />
    
        const ExportData = 'Exportar datos'
        const ExportDataRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Exportar Datos").consultar}  onChange={e => handleChangePermiso("Exportar Datos","consultar",e.checked)}/>
        const ExportDataAll = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Exportar Datos").exportarTodos}  tooltip="Exportar para todos" onChange={e => handleChangePermiso("Exportar Datos","exportarTodos",e.checked)}/>
        const ExportDataSelectAll = <Checkbox checked={validarAllSelected("Exportar Datos")}  onChange={e => handleChangeAllSelection("Exportar Datos",e.checked)}/>

        const Client = 'Parametrizar cliente'
        const ClientRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Clientes").consultar}  onChange={e => handleChangePermiso("Clientes","consultar",e.checked)}/>
        const ClientCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[0]}  onChange={e => handleChangePermiso("Clientes",0,e.checked)}/>
        const ClientUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[1]}  onChange={e => handleChangePermiso("Clientes",1,e.checked)}/>
        const ClientDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Clientes").permisos[2]}  onChange={e => handleChangePermiso("Clientes",2,e.checked)}/>
        const ClientSelectAll = <Checkbox checked={validarAllSelected("Clientes")} onChange={e => handleChangeAllSelection("Clientes",e.checked)} />
    
        const Project = 'Parametrizar proyecto'
        const ProjectRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proyectos").consultar}  onChange={e => handleChangePermiso("Proyectos","consultar",e.checked)}/>
        const ProjectCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[0]}  onChange={e => handleChangePermiso("Proyectos",0,e.checked)}/>
        const ProjectUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[1]}  onChange={e => handleChangePermiso("Proyectos",1,e.checked)}/>
        const ProjectDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proyectos").permisos[2]}  onChange={e => handleChangePermiso("Proyectos",2,e.checked)}/>
        const ProjectSelectAll = <Checkbox checked={validarAllSelected("Proyectos")} onChange={e => handleChangeAllSelection("Proyectos",e.checked)} />
    
        const Aplication = 'Parametrizar aplicación'
        const AplicationRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Aplicaciones").consultar}  onChange={e => handleChangePermiso("Aplicaciones","consultar",e.checked)}/>
        const AplicationCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[0]}  onChange={e => handleChangePermiso("Aplicaciones",0,e.checked)}/>
        const AplicationUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[1]}  onChange={e => handleChangePermiso("Aplicaciones",1,e.checked)}/>
        const AplicationDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Aplicaciones").permisos[2]}  onChange={e => handleChangePermiso("Aplicaciones",2,e.checked)}/>
        const AplicationSelectAll = <Checkbox checked={validarAllSelected("Aplicaciones")} onChange={e => handleChangeAllSelection("Aplicaciones",e.checked)} />
    
        const BusinessAreas = 'Parametrizar área de negocio'
        const BusinessAreasRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").consultar}  onChange={e => handleChangePermiso("Áreas de Negocio","consultar",e.checked)}/>
        const BusinessAreasCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[0]}  onChange={e => handleChangePermiso("Áreas de Negocio",0,e.checked)}/>
        const BusinessAreasUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[1]}  onChange={e => handleChangePermiso("Áreas de Negocio",1,e.checked)}/>
        const BusinessAreasDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Áreas de Negocio").permisos[2]}  onChange={e => handleChangePermiso("Áreas de Negocio",2,e.checked)}/>
        const BusinessAreasSelectAll = <Checkbox checked={validarAllSelected("Áreas de Negocio")} onChange={e => handleChangeAllSelection("Áreas de Negocio",e.checked)} />
    
        const ActivityType = 'Parametrizar tipo Actividad'
        const ActivityTypeRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").consultar}  onChange={e => handleChangePermiso("Tipos de Actividad","consultar",e.checked)}/>
        const ActivityTypeCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[0]}  onChange={e => handleChangePermiso("Tipos de Actividad",0,e.checked)}/>
        const ActivityTypeUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[1]}  onChange={e => handleChangePermiso("Tipos de Actividad",1,e.checked)}/>
        const ActivityTypeDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Actividad").permisos[2]}  onChange={e => handleChangePermiso("Tipos de Actividad",2,e.checked)}/>
        const ActivityTypeSelectAll = <Checkbox checked={validarAllSelected("Tipos de Actividad")} onChange={e => handleChangeAllSelection("Tipos de Actividad",e.checked)} />
    
        const Position = 'Parametrizar cargo'
        const PositionRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargos").consultar}  onChange={e => handleChangePermiso("Cargos","consultar",e.checked)}/>
        const PositionCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[0]}  onChange={e => handleChangePermiso("Cargos",0,e.checked)}/>
        const PositionUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[1]}  onChange={e => handleChangePermiso("Cargos",1,e.checked)}/>
        const PositionDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargos").permisos[2]}  onChange={e => handleChangePermiso("Cargos",2,e.checked)}/>
        const PositionSelectAll = <Checkbox checked={validarAllSelected("Cargos")} onChange={e => handleChangeAllSelection("Cargos",e.checked)} />
    
        const Rol = 'Parametrizar rol'
        const RolRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Roles").consultar}  onChange={e => handleChangePermiso("Roles","consultar",e.checked)}/>
        const RolCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Roles").permisos[0]}  onChange={e => handleChangePermiso("Roles",0,e.checked)}/>
        const RolUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Roles").permisos[1]}  onChange={e => handleChangePermiso("Roles",1,e.checked)}/>
        const RolDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Roles").permisos[2]}  onChange={e => handleChangePermiso("Roles",2,e.checked)}/>
        const RolSelectAll = <Checkbox checked={validarAllSelected("Roles")} onChange={e => handleChangeAllSelection("Roles",e.checked)} />
    
        const Calendar = 'Parametrizar Calendario'
        const CalendarRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Calendario").consultarCalendario}  onChange={e => handleChangePermiso("Calendario","consultarCalendario",e.checked)}/>
        const CalendarManage = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Calendario").permisos[0]}  tooltip="Parametrizar" onChange={e => handleChangePermiso("Calendario",0,e.checked)}/>
        const CalendarSelectAll = <Checkbox checked={validarAllSelected("Calendario")} onChange={e => handleChangeAllSelection("Calendario",e.checked)} />
    
        const DownloadZip = 'Descargar Zip'
        const DownloadZipRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Descargar Zip").DescargarZip}  tooltip="Descargar Zip" onChange={e => handleChangePermiso("Descargar Zip","DescargarZip",e.checked)}/>
        const DownloadZipSelectAll = <Checkbox checked={validarAllSelected("Descargar Zip")} onChange={e => handleChangeAllSelection("Descargar Zip",e.checked)} />
    
        const Closes = 'Procesos de Cierre'
        const RunClose = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Procesos Cierre").permisos[1]}  tooltip="Cierre Definitivo" onChange={e => handleChangePermiso("Procesos Cierre",1,e.checked)}/>
        const Notification = <Checkbox checked={checkedNotification}  tooltip="Quien lo tenga marcado recibirá mensajes semanales y mensuales" onChange={e => handleChangePermiso("Procesos Cierre","checkedNotification",e.checked)}/>
        const CloseOpen = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Procesos Cierre").permisos[0]}  tooltip="Abrir Mes" onChange={e => handleChangePermiso("Procesos Cierre",0,e.checked)}/>
        const ClosesSelectAll = <Checkbox checked={validarAllSelected("Procesos Cierre")} onChange={e => handleChangeAllSelection("Procesos Cierre",e.checked)} />
    
        const Historical = 'Histórico'
        const HistoricalRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Historico").consultar}  onChange={e => handleChangePermiso("Historico","consultar",e.checked)}/>
        const HistoricalSelectAll = <Checkbox checked={validarAllSelected("Historico")} onChange={e => handleChangeAllSelection("Historico",e.checked)} />
    
        const DashBoards = 'DashBoards'
        const DBActivityLogger = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards").DashBoards}  tooltip="Registrador de actividades" onChange={e => handleChangePermiso("Dashboards","DashBoards",e.checked)}/>
        const DBWorkload = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[0]}  tooltip="Carga de trabajo y proyectos" onChange={e => handleChangePermiso("Dashboards",0,e.checked)}/>
        const DBDetailTypeActivity = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[1]}  tooltip="Detalle tipo de actividad" onChange={e => handleChangePermiso("Dashboards",1,e.checked)}/>
        const DBDetailTypeActivityUser = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards").permisos[2]}  tooltip="Detalle tipo de actividad funcionario" onChange={e => handleChangePermiso("Dashboards",2,e.checked)}/>
        const DBSelectAll = <Checkbox checked={validarAllSelected("Dashboards")} onChange={e => handleChangeAllSelection("Dashboards",e.checked)} />
    
        const DBPowerBi = 'Dashboard Powerbi'
        const DBReadPowerBi = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").DBPowerBI}  onChange={e => handleChangePermiso("Dashboard PowerBI","DBPowerBI",e.checked)}/>
        const DBPowerBiClient = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").permisos[1]}  tooltip="Dashboard de powerbi de cliente" onChange={e => handleChangePermiso("Dashboard PowerBI",1,e.checked)}/>
        const DBPlusPowerBi = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI").permisos[0]}  tooltip="Dashboard de powerbi con licencia" onChange={e => handleChangePermiso("Dashboard PowerBI",0,e.checked)}/>
        const DBPlusPowerBiSelectAll = <Checkbox checked={validarAllSelected("Dashboard PowerBI")} onChange={e => handleChangeAllSelection("Dashboard PowerBI",e.checked)} />
    
        const NoveltyAnotherOfficial = 'Novedades otro funcionario'
        const NoveltyAnotherOfficialManagement = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Actividades").permisos[3]}  tooltip="Gestionar novedades a otros funcionarios" onChange={e => handleChangePermiso("Actividades",3,e.checked)}/>
        const NoveltyAnotherOfficialSelectAll = <Checkbox checked={validarAllSelected("Actividades*3")} onChange={e => handleChangeAllSelection("Actividades*3",e.checked)} />
    
        const IndividualMonthlyReport = 'Informe mensual individual'
        const IndividualMonthlyReportRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").consultar}  onChange={e => handleChangePermiso("Informe mensual individual","consultar",e.checked)}/>
        const IndividualMonthlyReportReadOtherOfficial = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").permisos[0]}  tooltip="Consultar informe mensual otro funcionario" onChange={e => handleChangePermiso("Informe mensual individual",0,e.checked)}/>
        const IndividualMonthlyReportReopen = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Informe mensual individual").permisos[1]}  tooltip="Reabrir informe mensual" onChange={e => handleChangePermiso("Informe mensual individual",1,e.checked)}/>
        const IndividualMonthlyReportSelectAll = <Checkbox checked={validarAllSelected("Informe mensual individual")} onChange={e => handleChangeAllSelection("Informe mensual individual",e.checked)} />
    
        const InterestedPerson = 'Parametrizar personas interesadas'
        const InterestedPersonRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Personas interesadas").consultar}  onChange={e => handleChangePermiso("Personas interesadas","consultar",e.checked)}/>
        const InterestedPersonCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[0]}  onChange={e => handleChangePermiso("Personas interesadas",0,e.checked)}/>
        const InterestedPersonUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[1]}  onChange={e => handleChangePermiso("Personas interesadas",1,e.checked)}/>
        const InterestedPersonDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Personas interesadas").permisos[2]}  onChange={e => handleChangePermiso("Personas interesadas",2,e.checked)}/>
        const InterestedPersonSelectAll = <Checkbox checked={validarAllSelected("Personas interesadas")} onChange={e => handleChangeAllSelection("Personas interesadas",e.checked)} />
    
        const Objective = 'Parametrizar objetivos'
        const ObjectiveRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos").consultar}  onChange={e => handleChangePermiso("Objetivos","consultar",e.checked)}/>
        const ObjectiveCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[0]}  onChange={e => handleChangePermiso("Objetivos",0,e.checked)}/>
        const ObjectiveUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[1]}  onChange={e => handleChangePermiso("Objetivos",1,e.checked)}/>
        const ObjectiveDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos").permisos[2]}  onChange={e => handleChangePermiso("Objetivos",2,e.checked)}/>
        const ObjectiveSelectAll = <Checkbox checked={validarAllSelected("Objetivos")} onChange={e => handleChangeAllSelection("Objetivos",e.checked)} />
    
        const Contract = 'Parametrizar contratos'
        const ContractRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").consultar}  onChange={e => handleChangePermiso("Gestionar Contratos","consultar",e.checked)}/>
        const ContractCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[0]}  onChange={e => handleChangePermiso("Gestionar Contratos",0,e.checked)}/>
        const ContractUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[1]}  onChange={e => handleChangePermiso("Gestionar Contratos",1,e.checked)}/>
        const ContractDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestionar Contratos").permisos[2]}  onChange={e => handleChangePermiso("Gestionar Contratos",2,e.checked)}/>
        const ContractSelectAll = <Checkbox checked={validarAllSelected("Gestionar Contratos")} onChange={e => handleChangeAllSelection("Gestionar Contratos",e.checked)} />
    
        const Deliverable = 'Parametrizar entregables'
        const DeliverableRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Entregables").consultar}  onChange={e => handleChangePermiso("Entregables","consultar",e.checked)}/>
        const DeliverableCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[0]}  onChange={e => handleChangePermiso("Entregables",0,e.checked)}/>
        const DeliverableUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[1]}  onChange={e => handleChangePermiso("Entregables",1,e.checked)}/>
        const DeliverableDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Entregables").permisos[2]}  onChange={e => handleChangePermiso("Entregables",2,e.checked)}/>
        const DeliverableSelectAll = <Checkbox checked={validarAllSelected("Entregables")} onChange={e => handleChangeAllSelection("Entregables",e.checked)} />
    
        const Service = 'Parametrizar servicios'
        const ServiceRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Servicios").consultar}  onChange={e => handleChangePermiso("Servicios","consultar",e.checked)}/>
        const ServiceCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[0]}  onChange={e => handleChangePermiso("Servicios",0,e.checked)}/>
        const ServiceUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[1]}  onChange={e => handleChangePermiso("Servicios",1,e.checked)}/>
        const ServiceDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Servicios").permisos[2]}  onChange={e => handleChangePermiso("Servicios",2,e.checked)}/>
        const ServiceSelectAll = <Checkbox checked={validarAllSelected("Servicios")} onChange={e => handleChangeAllSelection("Servicios",e.checked)} />
    
        const EquipoTrabajo = 'Parametrizar equipos de trabajo'
        const EquipoRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").consultar}  onChange={e => handleChangePermiso("Gestión de Equipos de Trabajo","consultar",e.checked)}/>
        const EquipoCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[0]}  onChange={e => handleChangePermiso("Gestión de Equipos de Trabajo",0,e.checked)}/>
        const EquipoUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[1]}  onChange={e => handleChangePermiso("Gestión de Equipos de Trabajo",1,e.checked)}/>
        const EquipoDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Gestión de Equipos de Trabajo").permisos[2]}  onChange={e => handleChangePermiso("Gestión de Equipos de Trabajo",2,e.checked)}/>
        const EquipoSelectAll = <Checkbox checked={validarAllSelected("Gestión de Equipos de Trabajo")} onChange={e => handleChangeAllSelection("Gestión de Equipos de Trabajo",e.checked)} />
    
        const TipoProyecto = 'Parametrizar tipos de proyectos'
        const TipoProyectoRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").consultar}  onChange={e => handleChangePermiso("Tipos de Proyectos","consultar",e.checked)}/>
        const TipoProyectoCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[0]}  onChange={e => handleChangePermiso("Tipos de Proyectos",0,e.checked)}/>
        const TipoProyectoUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[1]}  onChange={e => handleChangePermiso("Tipos de Proyectos",1,e.checked)}/>
        const TipoProyectoDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Tipos de Proyectos").permisos[2]}  onChange={e => handleChangePermiso("Tipos de Proyectos",2,e.checked)}/>
        const TipoProyectoSelectAll = <Checkbox checked={validarAllSelected("Tipos de Proyectos")} onChange={e => handleChangeAllSelection("Tipos de Proyectos",e.checked)} />
    
        const organizationChart = 'Organigrama'
        const organizationChartRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Organigrama").consultar}  onChange={e => handleChangePermiso("Organigrama","consultar",e.checked)}/>
        const organizationChartSelectAll = <Checkbox checked={validarAllSelected("Organigrama")} onChange={e => handleChangeAllSelection("Organigrama",e.checked)} />
    
        const DetailPending = 'Detalle de pendientes'
        const DetailPendingRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Detalle de Pendientes").consultar}  onChange={e => handleChangePermiso("Detalle de Pendientes","consultar",e.checked)}/>
        const DetailPendingSelectAll = <Checkbox checked={validarAllSelected("Detalle de Pendientes")} onChange={e => handleChangeAllSelection("Detalle de Pendientes",e.checked)} />
    
        const UserClient = 'Consultar usuarios de clientes'
        const UserClientRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Usuarios de clientes").consultar}  onChange={e => handleChangePermiso("Usuarios de clientes","consultar",e.checked)}/>
        const UserClientSelectAll = <Checkbox checked={validarAllSelected("Usuarios de clientes")} onChange={e => handleChangeAllSelection("Usuarios de clientes",e.checked)} />
    
        const Supplier = 'Parametrizar proveedores'
        const SupplierRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proveedores").consultar}  onChange={e => handleChangePermiso("Proveedores","consultar",e.checked)}/>
        const SupplierCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[0]}  onChange={e => handleChangePermiso("Proveedores",0,e.checked)}/>
        const SupplierUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[1]}  onChange={e => handleChangePermiso("Proveedores",1,e.checked)}/>
        const SupplierDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Proveedores").permisos[2]}  onChange={e => handleChangePermiso("Proveedores",2,e.checked)}/>
        const SupplierSelectAll = <Checkbox checked={validarAllSelected("Proveedores")} onChange={e => handleChangeAllSelection("Proveedores",e.checked)} />
    
        const FilterSupplier = 'Filtros de proveedor'
        const FilterSupplierNDB1 = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards - Prov").permisos[1]}  tooltip="Dashboard detalle tipos de actividad con filtro de proveedor" onChange={e => handleChangePermiso("Dashboards - Prov",1,e.checked)}/>
        const FilterSupplierNDB2 = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboards - Prov").permisos[2]}  tooltip="Dashboard tipos de actividad - funcionario con filtro de proveedor" onChange={e => handleChangePermiso("Dashboards - Prov",2,e.checked)}/>
        const FilterSupplierExportData = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Exportar datos proveedor").consultar}  tooltip="Exportar datos con filtro de proveedor" onChange={e => handleChangePermiso("Exportar datos proveedor","consultar",e.checked)}/>
        const FilterSupplierSelectAll = <Checkbox checked={validarAllSelected("Dashboards - Prov")} onChange={e => handleChangeAllSelection("Dashboards - Prov",e.checked)} />
    
        const DBPowerBiFilterSupplier = 'Dashboard Powerbi con filtro de proveedor'
        const DBReadPowerBiFilterSupplier = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").DBPowerBISupplier}  onChange={e => handleChangePermiso("Dashboard PowerBI - Proveedor","DBPowerBISupplier",e.checked)}/>
        const DBPowerBiFilterSupplierClient = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").permisos[1]}  tooltip="Dashboard de powerbi de cliente" onChange={e => handleChangePermiso("Dashboard PowerBI - Proveedor",1,e.checked)}/>
        const DBPlusPowerBiFilterSupplier = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Dashboard PowerBI - Proveedor").permisos[0]}  tooltip="Dashboard de powerbi con licencia" onChange={e => handleChangePermiso("Dashboard PowerBI - Proveedor",0,e.checked)}/>
        const DBPlusPowerBiFilterSupplierSelectAll = <Checkbox checked={validarAllSelected("Dashboard PowerBI - Proveedor")} onChange={e => handleChangeAllSelection("Dashboard PowerBI - Proveedor",e.checked)} />
    
    
        const uploadImage = 'Cargar Imagen Corporativa'
        const uploadImageRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Cargar Imagen Corporativa").consultar}  tooltip="Menú de Cargar Imagen Corporativa" onChange={e => handleChangePermiso("Cargar Imagen Corporativa","consultar",e.checked)}/>
        const uploadImageSelectAll = <Checkbox checked={validarAllSelected("Cargar Imagen Corporativa")} onChange={e => handleChangeAllSelection("Cargar Imagen Corporativa",e.checked)} tooltip="Menú de Cargar Imagen Corporativa" />
    
        //Parametrizar objetivos estrátegicos
        const strategicObjectives = 'Objetivos Estratégicos'
        const strategicObjectivesRead = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").consultar}  onChange={e => handleChangePermiso("Objetivos Estratégicos","consultar",e.checked)}/>
        const strategicObjectivesCreate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[0]}  onChange={e => handleChangePermiso("Objetivos Estratégicos",0,e.checked)}/>
        const strategicObjectivesUpdate = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[1]}  onChange={e => handleChangePermiso("Objetivos Estratégicos",1,e.checked)}/>
        const strategicObjectivesDelete = <Checkbox checked={permisosRol.find(permiso => permiso.menu === "Objetivos Estratégicos").permisos[2]}  onChange={e => handleChangePermiso("Objetivos Estratégicos",2,e.checked)}/>
        const strategicObjectivesSelectAll = <Checkbox checked={validarAllSelected("Objetivos Estratégicos")} onChange={e => handleChangeAllSelection("Objetivos Estratégicos",e.checked)} />        
       
        var TablaPermisos = [            
            { "options": User, "read": UserRead, "create": UserCreate, "update": UserUpdate, "delete": UserDelete, "selectAll": UserSelectAll },
            { "options": Activity, "read": ActivityRead, "create": ActivityCreate, "update": ActivityUpdate, "delete": ActivityDelete, "selectAll": ActivitySelectAll },
            { "options": ExportData, "read": ExportDataRead, "create": ExportDataAll, "selectAll": ExportDataSelectAll },
            { "options": Client, "read": ClientRead, "create": ClientCreate, "update": ClientUpdate, "delete": ClientDelete, "selectAll": ClientSelectAll },
            { "options": Project, "read": ProjectRead, "create": ProjectCreate, "update": ProjectUpdate, "delete": ProjectDelete, "selectAll": ProjectSelectAll },
            { "options": Aplication, "read": AplicationRead, "create": AplicationCreate, "update": AplicationUpdate, "delete": AplicationDelete, "selectAll": AplicationSelectAll },
            { "options": BusinessAreas, "read": BusinessAreasRead, "create": BusinessAreasCreate, "update": BusinessAreasUpdate, "delete": BusinessAreasDelete, "selectAll": BusinessAreasSelectAll },
            { "options": ActivityType, "read": ActivityTypeRead, "create": ActivityTypeCreate, "update": ActivityTypeUpdate, "delete": ActivityTypeDelete, "selectAll": ActivityTypeSelectAll },
            { "options": Position, "read": PositionRead, "create": PositionCreate, "update": PositionUpdate, "delete": PositionDelete, "selectAll": PositionSelectAll },
            { "options": Rol, "read": RolRead, "create": RolCreate, "update": RolUpdate, "delete": RolDelete, "selectAll": RolSelectAll },
            { "options": Calendar, "read": CalendarRead, "create": CalendarManage, "selectAll": CalendarSelectAll },
            { "options": DownloadZip, "read": DownloadZipRead, "selectAll": DownloadZipSelectAll },
            { "options": Closes, "read": RunClose, "create": CloseOpen, "update": Notification, "selectAll": ClosesSelectAll },
            { "options": Historical, "read": HistoricalRead, "selectAll": HistoricalSelectAll },
            { "options": DashBoards, "read": DBActivityLogger, "create": DBWorkload, "update": DBDetailTypeActivity, "delete": DBDetailTypeActivityUser, "selectAll": DBSelectAll },
            { "options": DBPowerBi, "read": DBReadPowerBi, "create": DBPlusPowerBi, "update": DBPowerBiClient, "selectAll": DBPlusPowerBiSelectAll },
            { "options": NoveltyAnotherOfficial, "read": NoveltyAnotherOfficialManagement, "selectAll": NoveltyAnotherOfficialSelectAll },
            { "options": IndividualMonthlyReport, "read": IndividualMonthlyReportRead, "create": IndividualMonthlyReportReadOtherOfficial, "update": IndividualMonthlyReportReopen, "selectAll": IndividualMonthlyReportSelectAll },
            { "options": InterestedPerson, "read": InterestedPersonRead, "create": InterestedPersonCreate, "update": InterestedPersonUpdate, "delete": InterestedPersonDelete, "selectAll": InterestedPersonSelectAll },
            { "options": Objective, "read": ObjectiveRead, "create": ObjectiveCreate, "update": ObjectiveUpdate, "delete": ObjectiveDelete, "selectAll": ObjectiveSelectAll },
            { "options": Contract, "read": ContractRead, "create": ContractCreate, "update": ContractUpdate, "delete": ContractDelete, "selectAll": ContractSelectAll },
            { "options": Deliverable, "read": DeliverableRead, "create": DeliverableCreate, "update": DeliverableUpdate, "delete": DeliverableDelete, "selectAll": DeliverableSelectAll },
            { "options": Service, "read": ServiceRead, "create": ServiceCreate, "update": ServiceUpdate, "delete": ServiceDelete, "selectAll": ServiceSelectAll },
            { "options": EquipoTrabajo, "read": EquipoRead, "create": EquipoCreate, "update": EquipoUpdate, "delete": EquipoDelete, "selectAll": EquipoSelectAll },
            { "options": TipoProyecto, "read": TipoProyectoRead, "create": TipoProyectoCreate, "update": TipoProyectoUpdate, "delete": TipoProyectoDelete, "selectAll": TipoProyectoSelectAll },
            { "options": organizationChart, "read": organizationChartRead, "selectAll": organizationChartSelectAll },
            { "options": DetailPending, "read": DetailPendingRead, "selectAll": DetailPendingSelectAll },
            { "options": UserClient, "read": UserClientRead, "selectAll": UserClientSelectAll },
            { "options": Supplier, "read": SupplierRead, "create": SupplierCreate, "update": SupplierUpdate, "delete": SupplierDelete, "selectAll": SupplierSelectAll },
            { "options": FilterSupplier, "read": FilterSupplierNDB1, "create": FilterSupplierNDB2, "update": FilterSupplierExportData, "selectAll": FilterSupplierSelectAll },
            { "options": DBPowerBiFilterSupplier, "read": DBReadPowerBiFilterSupplier, "create": DBPlusPowerBiFilterSupplier, "update": DBPowerBiFilterSupplierClient, "selectAll": DBPlusPowerBiFilterSupplierSelectAll },
            { "options": uploadImage, "read": uploadImageRead, "selectAll": uploadImageSelectAll },
            { "options": strategicObjectives, "read": strategicObjectivesRead, "create": strategicObjectivesCreate, "update": strategicObjectivesUpdate, "delete": strategicObjectivesDelete, "selectAll": strategicObjectivesSelectAll },

        ]

}
    return (

        <>
            <Toast ref={toast} />
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Actualizar rol" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-2">
                            <label className="labels" >Id rol<span className="require">*</span></label>
                            <InputText id="id" type="text" name="id" disabled value={idRol} />
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label className="labels" >Rol<span className="require">*</span></label>
                            <InputText id="rol" type="text" name="rol" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onRolChange} value={rol}
                                placeholder="Registre el nombre del rol"
                                className={rolError || errorDuplicated ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{rolError ? mensajeRol : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label className="labels" >Estado<span className="require">*</span></label>
                            <Dropdown value={selectedEstado} options={estados} onChange={onEstadoChange} name="estado" optionLabel="estado" placeholder="Seleccione Estado" />
                        </div>

                    </form>
                </div>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={TablaPermisos} className="p-datatable-responsive-demo" paginator rows={10} >
                            <Column className="p-column" field="options" header="Opciones" />
                            <Column className="p-column" field="read" header="Consultar" />
                            <Column className="p-column" field="create" header="Crear" />
                            <Column className="p-column" field="update" header="Editar" />
                            <Column className="p-column" field="delete" header="Eliminar" />
                            <Column className="p-column" field="selectAll" header="Seleccionar todo" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>

        </>
    )


}
export default BtnActualizarRol
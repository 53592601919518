import React,{useEffect,useState} from "react";

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import axios from 'axios';
import moment from 'moment';

import 'primeicons/primeicons.css';
import Cookies from "universal-cookie";

const url = process.env.REACT_APP_BASE_URL + '/v1/api/token/refresh'

const RefreshToken = ({children}) =>{
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
 

    useEffect(() =>{        
        const checkTokenExpiration = () => {            
            const token = sessionStorage.getItem('token');
            if (token) {
                const tokenPayload = JSON.parse(atob(token.split(".")[1]));
                const expirationTime = tokenPayload.exp-moment().unix();                          
                if (expirationTime <= 300 && expirationTime>25){
                    setIsDialogVisible(true)
                    setDialogMessage(`Su sesión esta por expirar restan (${Math.round(expirationTime/60 * 10) / 10} minutos) ¿Desea continuar trabajando o salir del aplicativo?`)
                }else if(expirationTime<25){                    
                    setIsDialogVisible(false);
                    sessionStorage.clear()
                    window.location.href = "/singOff"
                }
            }
        }

 
        const interval = setInterval(() => {
            checkTokenExpiration();
        }, 1000); // Actualiza cada segundo


        return () => clearInterval(interval);        
    },[])

    const  refresh = async () =>{
       await axios.post(url,null,
            {
                headers:
                {

                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
        })
        .then(response =>{
            sessionStorage.setItem("token", response.data)
            setIsDialogVisible(false);
        })
    }

    const onHideDialog = () => {
        const cookies = new Cookies();
        try {
            setIsDialogVisible(false);
            // Eliminar todas las cookies
            const cookieNames = Object.keys(cookies.getAll());
            cookieNames.forEach(name => {
                cookies.remove(name, { path: '/' });
            });
            
            // Limpiar sessionStorage
            sessionStorage.clear();
            
            // Redirigir al login
             window.location.href= "/singOff";
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
            window.location.href = "/singOff";
        }
    };
    
    
    return (
        <>
            {children}                       
                <Dialog 
                    visible={isDialogVisible} 
                    onHide={onHideDialog} 
                    header="Advertencia" 
                    modal 
                    className="dialog-demo"
                    breakpoints={{ '960px': '75vw' }}
                    closable={false}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem', marginRight: '1rem'}} />
                        <span>{dialogMessage}</span>
                    </div>
                    <div style={{ textAlign: "right", marginTop: "1rem" }}>
                        <Button label="Cerrar Sesión" className="p-button-text" onClick={onHideDialog} style={{ marginLeft: "0.5rem" }} icon="pi pi-times" text raised/>
                        <Button label="Extender Sesión" onClick={refresh} icon="pi pi-check" />
                    </div>
                </Dialog>
                {/*// eslint-disable-line react-hooks/exhaustive-deps*/}
        </>
    );

}




export default RefreshToken;
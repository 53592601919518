import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';

import Cookies from 'universal-cookie'
const cookies = new Cookies()

const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/objective/update'
const urlCreate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/objective/create'

const ConfirmBtn = ({ objectiveProp, percentageProp, dateStartProp, dateEndProp, idProyectProp, setShowModal, setRegisteredObjective,
    idObjectiveProp, projectsProp, projectsOldProp, idProjectObjective, projectObjectiveCreationDate, individualMonthReportProp,
    creationDateProp, createdByProp, assingnedNewProjectObjectiveProp, selectedProjectUpdateProp, setRunRenderProp, deleteDetailProp,
    runRenderProp, setDisplayBasicProp, updateDatailProp, statusProp, dateProp,
    reloadObjectiveProp, setReloadObjectiveProp, runRenderBlockProp, setRunRenderBlockProp, noDeleteObjectiveProp, createNewObjectiveProp, createProjectsProp,
    tittle, menssage, action, icon, classProp, label, tooltipProp, dontDeleteProp, setInputObjectiveProp }) => {


    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabledButton, setDisabledButton] = useState(false);

    const service = () => {
        if (action === "Create") {
            createObjective()
            setDisabledButton(true)
        } else if (action === "Update") {
            updateObjective()
            setDisabledButton(true)
        } else if (action === "Delete") {
            setDisabledButton(true)
            deleteObjective()
        }
    }

    const usuario = cookies.get('usuario')
    const createObjective = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                creadopor: usuario,
                descripcion: objectiveProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                proyectos: createNewObjectiveProp ?
                    createProjectsProp.map(item => {
                        return {
                            ...item,
                            id: undefined
                        }
                    })
                    :
                    [{
                        estado: 1,
                        fechacreacion: new Date(),
                        fechacumplimiento: dateProp,
                        fechafinvigencia: dateEndProp,
                        fechainiciovigencia: dateStartProp,
                        porcentajecumplimiento: percentageProp,
                        proyecto: {
                            id: idProyectProp
                        },
                    }]
            }
        )
        /* console.log(json) */
        await axios.post(urlCreate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                if (setShowModal) {
                    setShowModal(true)
                }
                if (setRegisteredObjective) {
                    setRegisteredObjective(true)
                }
                if (setDisplayBasicProp) {
                    setDisplayBasicProp(false)
                }
                if (setRunRenderProp) {
                    setRunRenderProp(runRenderProp + 1)
                }
                if (setRunRenderBlockProp) {
                    setRunRenderBlockProp(runRenderBlockProp + 1)
                }
                setDisplayConfirmation(false)
                setDisabledButton(true)
                setInputObjectiveProp({
                    objective: ''
                })
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 });
            })
    }


    const updateObjective = async () => {

        const json = JSON.stringify(
            {

                actualizadopor: usuario,
                creadopor: createdByProp,
                descripcion: objectiveProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: creationDateProp,
                id: idObjectiveProp,
                proyectos: updateDatailProp ? [{
                    estado: statusProp,
                    fechacreacion: assingnedNewProjectObjectiveProp ? new Date() : projectObjectiveCreationDate,
                    fechacumplimiento: dateProp,
                    fechafinvigencia: dateEndProp,
                    fechainiciovigencia: dateStartProp,
                    id: assingnedNewProjectObjectiveProp ? undefined : idProjectObjective,
                    porcentajecumplimiento: percentageProp,
                    proyecto: {
                        id: parseInt(idProyectProp)
                    },
                }]
                    :
                    []

            })

        /* console.log(json) */

        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                if (individualMonthReportProp === true) {
                    setTimeout(() => {
                        setRunRenderProp(runRenderProp + 1)
                        if (setRunRenderBlockProp !== undefined) {
                            setRunRenderBlockProp(runRenderBlockProp + 1)
                        }
                        setReloadObjectiveProp(reloadObjectiveProp + 1)
                        if (deleteDetailProp === false) {
                            setDisplayBasicProp(false)
                        }
                        setDisplayConfirmation(false)
                    }, [500])
                } else {
                    window.location.href = "/registroActividades/objetivos"
                }
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 });
                }
            })
    }

    const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/objective/delete?id=' + idObjectiveProp
    const deleteObjective = async () => {
        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)

                if (individualMonthReportProp === true) {
                    setRunRenderProp(runRenderProp + 1)
                    if (setRunRenderBlockProp !== undefined) {
                        setRunRenderBlockProp(runRenderBlockProp + 1)
                    }
                    setReloadObjectiveProp(reloadObjectiveProp + 1)
                    /* window.location.href = "/registroActividades/informeMensualIndividual" */
                    setDisplayConfirmation(false)
                } else {
                    window.location.href = "/registroActividades/objetivos"
                }
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 });
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                {noDeleteObjectiveProp || dontDeleteProp ?
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    :
                    <>
                        <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                        <Button label="Aceptar" icon="pi pi-check" onClick={() => service()} autoFocus disabled={disabledButton} />
                    </>
                }
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icon} onClick={() => onClick('displayConfirmation')} className={classProp} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    {
                        dontDeleteProp ?
                            <Dialog className="modalConfirm" header={tittle} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                                <div className="confirmation-content-modal">
                                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                    <span>{menssage}</span>
                                </div>
                            </Dialog>
                            :
                            <Dialog className="modalConfirm" header={tittle} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                                <div className="confirmation-content-modal">
                                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                    <span>{menssage}</span>
                                </div>
                            </Dialog>
                    }

                </div>
            </div>
        </>
    )
}

export default ConfirmBtn
import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import ConfirmBtn from "./ConfirmBtn";
import BtnUpdateDatail from './BtnUpdateDetail';
import { EstandarFormatoFecha, SumarDias, CambiarFormatoFecha } from '../Funciones';
import axios from 'axios';
import Cookies from 'universal-cookie';

const Objetivos = ({ objectivesProp, idDeliverableProp, deliverableProp, idObjectiveProp, objectiveProp, creationDateProp, createdByProp, idProjectProp, projectProp,
    individualMonthReportProp, setRunRenderProp, runRenderProp, readReportProp, reloadObjectiveProp, deliverablesProp, runRenderBlockProp, setRunRenderBlockProp,
    listObjectivesProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp }) => {

    const cookies = new Cookies();
    const usuario = cookies.get('usuario');
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const [detailObjectives, setDetailObjectives] = useState([])
    const [servicesActivities, setServicesActivities] = useState([])
    const [selectedMode, setSelectedMode] = useState('AsociacionObjetivo');

    useEffect(() => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        var newProjectProp = objectivesProp.map(item => {
            var newDate

            if (gmtHours < 0) {
                newDate = SumarDias(new Date(item.fechacumplimiento), 1)

            } else {
                newDate = new Date(item.fechacumplimiento)

            }
            return {
                estado: item.estado,
                fechacreacion: item.fechacreacion,
                fechacumplimiento: item.fechacumplimiento === null ? null : EstandarFormatoFecha(newDate),
                sendDate: item.fechacumplimiento,
                id: item.id,
                porcentajecumplimiento: item.porcentajecumplimiento,
                objetivo: item.objetivo,
                proyecto: item.proyecto
            }
        })

        if (individualMonthReportProp === true) {
            var newData = newProjectProp.filter(function (object) {
                var id = object.proyecto.id
                return id === parseInt(idProjectProp)
            })
            setDetailObjectives(newData)
        } else {
            setDetailObjectives(newProjectProp)
        }

    }, [objectivesProp, idProjectProp, individualMonthReportProp, deliverablesProp])


    useEffect(() => {
        const executeServices = async () => {
          
            if (dateStartMonthlyReportProp !== null && dateEndMonthlyReportProp !== null && selectedMode === "AsociacionActividades") {
            const newDateStart = CambiarFormatoFecha(dateStartMonthlyReportProp)
            const newDateEnd = CambiarFormatoFecha(dateEndMonthlyReportProp)
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
            const json = JSON.stringify({
                esadicional: "0",
                fecFinal: newDateEnd,
                fecInicial: newDateStart,
                usuario: usuario
            })

            try {

                const response = await axios.post(url, json,
                    {
                        headers:
                        {
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                        },
                    })
                const filter = response.data.filter(item => {
                    return item.idEntregable === idDeliverableProp
                })

                const newData = await Promise.all(filter.map(async (item) => {
                    let newDate;
                    const gmtHours = -new Date().getTimezoneOffset() / 60;
                    if (gmtHours < 0) {
                        newDate = SumarDias(new Date(item.fecha), 1);
                    } else {
                        newDate = new Date(item.fecha);
                    }

                    return {
                        ...item,
                        fecha: EstandarFormatoFecha(newDate),
                        sendDate: item.fecha,
                    };
                }));
                setServicesActivities(newData)

            }
            catch (error) {

            }
        }}

        if (dateStartMonthlyReportProp !== null && dateEndMonthlyReportProp !== null) {
            executeServices()
        }

    }, [idDeliverableProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp,objectivesProp,selectedMode])// eslint-disable-line react-hooks/exhaustive-deps

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    const acctions = (rowData) => {
        /* console.log(rowData) */
        return (
            <>
                {readReportProp ?
                    null
                    :
                    <BtnUpdateDatail
                        idObjectiveDeliverableProp={rowData.id}
                        idDeliverableProp={idDeliverableProp}
                        deliverableProp={deliverableProp}
                        idObjectiveProp={rowData.objetivo.id}
                        objectiveProp={rowData.objetivo.descripcion}
                        projectProp={rowData.proyecto}
                        clientProp={rowData.proyecto.cliente}
                        percentageProp={rowData.porcentajecumplimiento}
                        dateProp={rowData.sendDate}
                        creationDateProp={creationDateProp}
                        createdByProp={createdByProp}
                        individualMonthReportProp={individualMonthReportProp}
                        setRunRenderProp={setRunRenderProp}
                        runRenderProp={runRenderProp}
                        reloadObjectiveProp={reloadObjectiveProp}
                        runRenderBlockProp={runRenderBlockProp}
                        setRunRenderBlockProp={setRunRenderBlockProp}
                        listObjectivesProp={listObjectivesProp}
                    />
                }
                {readReportProp ?
                    null :
                    <ConfirmBtn
                        tittle="Confirmar Eliminación"
                        menssage="¿Está seguro de eliminar esta asociación?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                        action="Update"
                        label=""
                        classProp="p-button-danger"
                        icon="pi pi-trash"
                        tooltipProp="Eliminar"
                        idDeliverableProp={idDeliverableProp}
                        assingnedNewObjectiveDeliverableProp={true}
                        idObjectiveDeliverableProp={rowData.id}
                        idObjectiveProp={rowData.objetivo.id}
                        idProjectProp={rowData.proyecto.id}
                        creationDateObjectiveProp={rowData.fechacreacion}
                        deliverableProp={deliverableProp}
                        creationDateProp={creationDateProp}
                        percentageProp={rowData.porcentajecumplimiento}
                        createdByProp={createdByProp}
                        statusProp={3}
                        setRunRenderProp={setRunRenderProp}
                        runRenderProp={runRenderProp}
                        deleteDetailProp={true}
                        runRenderBlockProp={runRenderBlockProp}
                        setRunRenderBlockProp={setRunRenderBlockProp}
                    />
                }

            </>
        )
    }
    const onselectedModeChange = (e) => {
        setSelectedMode(e.value)
    }
    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Asociaciones" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                {
                    individualMonthReportProp ?
                        <div className="p-fluid p-formgrid p-grid">
                            <div style={{ display: 'contents' }} >
                                <div className="p-field p-col-12 p-md-3">
                                    <div className="p-field-radiobutton" style={{ marginBottom: '5px' }}>
                                        <RadioButton inputId="AsociacionObjetivo" name="AsociacionObjetivo" value="AsociacionObjetivo" onChange={onselectedModeChange} checked={selectedMode === 'AsociacionObjetivo'} />
                                        <label htmlFor="AsociacionObjetivo" style={{ fontWeight: '100' }}>Asociación Cliente Proyecto</label>
                                    </div>
                                    <div className="p-field-radiobutton" style={{ marginTop: '10px' }}>
                                        <RadioButton inputId="AsociacionActividades" name="AsociacionActividades" value="AsociacionActividades" onChange={onselectedModeChange} checked={selectedMode === 'AsociacionActividades'} />
                                        <label htmlFor="AsociacionActividades" style={{ fontWeight: '100' }}>Asociación de Actividades</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                }
                <div className="datatable-responsive-demo">
                    <div className="card">
                        {
                            selectedMode === 'AsociacionObjetivo' ?
                                <DataTable ref={dt} value={detailObjectives} className="p-datatable-responsive-demo" paginator rows={10}
                                    emptyMessage="No hay asociaciones registradas para este entregable" header={header} globalFilter={globalFilter}>
                                    <Column className="columna" field="proyecto.nombreproyecto" header="Proyecto" />
                                    <Column className="columna" field="objetivo.descripcion" header="Objetivos" />
                                    <Column className="columna" field="fechacumplimiento" header="Fecha cumplimiento" />
                                    <Column className="columna" field="porcentajecumplimiento" header="Porcentaje cumplimiento" />
                                    <Column header="Acciones" body={acctions} />
                                </DataTable>
                                :
                                <DataTable ref={dt} value={servicesActivities} className="p-datatable-responsive-demo" paginator rows={10}
                                    emptyMessage="No hay asociaciones registradas para este entregable" header={header} globalFilter={globalFilter}>
                                    <Column className="columna" field="fecha" header="Fecha" />
                                    <Column className="columna" field="cliente.razonsocial" header="Cliente" />
                                    <Column className="columna" field="proyecto.nombreproyecto" header="Proyecto" />
                                    <Column className="columna" field="numerohoras" header="Numero Horas" />
                                    <Column className="columna" field="descripcionactividad" header="Descripcion Actividad" />

                                </DataTable>
                        }
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default Objetivos
import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
import deleteAssiations from './DeleteAssociations';
import Services from '../../service/Services';
const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/supplier/updateSupplier'
const urlCrear = process.env.REACT_APP_BASE_URL + '/v1/api/supplier/createSupplier'

const ConfirmBtn = ({ tipoDocumentoProp, numeroDocumentoProp, nombreProp, dominioProp, idProveedorProp, idColaboradorProp, creadoporProp,
    usuarioProp, titulo, mensaje, accion, label, icono, clase, fechacreacionProp, renderProp, setRenderProp, setDisplayBasicProp }) => {

    const getServices = new Services();
    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [disabled, setDisabled] = useState(false)
    const servicio = () => {
        if (accion === "Crear") {
            crearProveedor()
            setDisabled(true);
        } else if (accion === "Actualizar") {
            actualizarProveedor()
            setDisabled(true);
        } else if (accion === "Eliminar") {
            setDisabled(true);
            eliminarProveedor()
        }
    }

    const crearProveedor = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                creadopor: usuarioProp,
                dominio: dominioProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                idempresa: {
                    id: 1
                },
                numerodocumento: numeroDocumentoProp,
                razonsocial: nombreProp,
                tipodocumento: tipoDocumentoProp,
            }
        )
        /* console.log(json) */
        await axios.post(urlCrear, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                setTimeout(() => {
                    setRenderProp(renderProp + 1);
                    setDisplayConfirmation(false);
                    setDisplayBasicProp(false);
                }, 1000);
            })
            .catch(function (error) {
                setTimeout(() => {
                    setDisabled(false)
                }, 1000);
                //console.log(error.response)
                if (error.response.status === 406) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const actualizarProveedor = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuarioProp,
                creadopor: creadoporProp,
                dominio: dominioProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: fechacreacionProp,
                id: idProveedorProp,
                idempresa: {
                    id: 1
                },
                numerodocumento: numeroDocumentoProp,
                razonsocial: nombreProp,
                tipodocumento: tipoDocumentoProp,
            }
        )
        /* console.log(json) */

        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                setTimeout(() => {
                    setRenderProp(renderProp + 1);
                    setDisplayConfirmation(false);
                    setDisplayBasicProp(false);
                }, 1000);
            })
            .catch(function (error) {
                setTimeout(() => {
                    setDisabled(false)
                }, 1000);
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const [servicesClients, setServicesClients] = useState(null)
    useEffect(() => {
        if (accion === 'Eliminar' && displayConfirmation === true) {
            getServices.getClientes().then(data => {
                setServicesClients(data)
            })
        }
    }, [accion, displayConfirmation])// eslint-disable-line react-hooks/exhaustive-deps

    const urlEliminar = process.env.REACT_APP_BASE_URL + '/v1/api/supplier/delete?id=' + idProveedorProp
    const eliminarProveedor = async () => {
        await axios.delete(urlEliminar,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                deleteAssiations(idProveedorProp, servicesClients)
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                setTimeout(() => {
                    setRenderProp(renderProp + 1);
                    setDisplayConfirmation(false);
                }, 1000);
            })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                //console.log(error.response)
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled} />
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={clase} style={{ margin: '5px 5px 0px' }} />
            <Dialog className="modalConfirm" header={titulo} visible={displayConfirmation} breakpoints={{ '960px': '75vw' }} style={{ maxWidth: '50vw' }} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                <div className="confirmation-content">
                    <div className="mensajeCofirmar">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span>{mensaje}</span>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ConfirmBtn
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import Objetivos from "./Objetivos";
import Services from "../../service/Services";
import BtnCrearEntregables from "./BtnCrearEntregables";
import ConfirmBtn from "./ConfirmBtn.js";
import BtnActualizarEntregables from './BtnActualizarEntregables'
import Cookies from "universal-cookie";
import { UseMedia } from "../Funciones";
import axios from "axios";
const cookies = new Cookies();


const ConsultarEntregables = ({ permissionsProp, fullTableProp, individualMonthReportProp, idProjectProp, projectProp, getClientsProp, dateStartValidityProp, dateEndValidityProp,
    setSelectedDeliverableProp, objectMonthlyReportDataProp, readReportProp, listReportsUsersProp, reloadObjectiveProp, setDisabledIndexProp, disabledIndexProp,
    selectedDeliverableProp, deliverablesProp, objectivesProp, projectsUserSessionProp, runRenderBlockProp, setRunRenderBlockProp, showAllDataProp, loadingProp,
    monthlyReportDataAllDataProp, emptyObjectivesProp, selectedObjectiveProp, servicesDeliverablesProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp }) => {

    const [deliverables, setDeliverables] = useState([]);
    const [loading, setLoading] = useState(true);

    const getServices = new Services();

    const [runRender, setRunRender] = useState(0)

    const usuario = cookies.get('usuario')

    useEffect(() => {
        let source;
        if (showAllDataProp === true && servicesDeliverablesProp !== undefined) {
            setDeliverables(servicesDeliverablesProp)
            setLoading(false)
        }
        if (individualMonthReportProp === false) {
            source = axios.CancelToken.source()
            getServices.getDeliverables().then(data => {
                if (data !== undefined) {
                    setDeliverables(data)
                    setLoading(false)
                }
            });
        }
        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }

    }, [runRender, servicesDeliverablesProp, showAllDataProp, individualMonthReportProp])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (showAllDataProp === false) {
            let deliverable = [];
            if (fullTableProp === true) {
                if (readReportProp === true && listReportsUsersProp.length !== 0) {
                    const deliverableData = JSON.parse(listReportsUsersProp[0].entregables);
                    deliverable = deliverableData.reduce((acc, element) => {
                        const result = deliverablesProp.find(obj => parseInt(obj.id) === element.id);
                        if (result) {
                            acc.push(result);
                        }
                        return acc;
                    }, []);
                } else {
                    deliverable = deliverablesProp.filter(item =>
                        item.objetivos.some(j => j.proyecto.id === parseInt(idProjectProp))
                    );
                }
            }

            // Filter out duplicates using a Set based on deliverable id
            const uniqueDeliverables = Array.from(new Set(deliverable.map(item => item.id)))
                .map(id => deliverable.find(item => item.id === id));

            const newUniqueData = uniqueDeliverables.map(item => ({
                actualizadopor: item.actualizadopor,
                creadopor: item.creadopor,
                descripcion: item.descripcion,
                estado: item.estado,
                fechaactualizado: item.fechaactualizado,
                fechacreacion: item.fechacreacion,
                id: item.id,
                objetivos: item.objetivos,
                proyecto: idProjectProp
            }));

            const newUniqueDataCreatedBy = newUniqueData.filter(object => object.creadopor === usuario);
            const newUniqueDataNoCreatedBy = newUniqueData.filter(object => object.creadopor !== usuario);

            const sendData = [...newUniqueDataCreatedBy, ...newUniqueDataNoCreatedBy];

            const filterObjective = sendData.filter(item =>
                selectedObjectiveProp.some(obj => obj.proyecto === idProjectProp &&
                    item.objetivos.some(j => j.objetivo.id === obj.id))
            );

            setDeliverables(filterObjective);
            setLoading(false);
        }
    }, [showAllDataProp, fullTableProp, readReportProp, listReportsUsersProp, deliverablesProp, idProjectProp, usuario, selectedObjectiveProp]);
                    /* const deliverableDuplicated = {}
                    const uniqueDeliverable = filterObjective.filter((object, index) => {
                        return deliverableDuplicated.hasOwnProperty(object.proyecto.id) ? false : (deliverableDuplicated[object.proyecto.id] = true)
                    }) */;

    var newSelectedObjectiveProp = selectedObjectiveProp?.filter(obj => obj.proyecto === idProjectProp)

    const dt = useRef(null);

    const [selectedDeliverable, setSelectedDeliverable] = useState(null);

    const onRowSelect = (e) => {
        var objectiveFilter = []
        objectivesProp.forEach(x => {
            e.data.objetivos.forEach(y => {
                if (x.id === y.objetivo.id) {
                    objectiveFilter.push(x)
                }
            })
        })
        var objectiveFilterProject = []
        objectiveFilter.forEach(element => {
            const result = element.proyectos.filter(function (object) {
                var id = parseInt(object.proyecto.id);
                return id === parseInt(idProjectProp);
            });
            result.forEach((i) => {
                objectiveFilterProject.push(element);
            });
        })

        var objectiveProject = objectiveFilterProject.map(item => {
            return {
                descripcion: item.descripcion,
                id: item.id,
                proyectos: item.proyectos.filter(object => object.proyecto.id === parseInt(idProjectProp))
            }
        })

        var sendObjectives = []
        e.data.objetivos.forEach(x => {
            objectiveProject.forEach(y => {
                sendObjectives.push({
                    id: x.id,
                    fechacumplimiento: x.fechacumplimiento,
                    porcentajecumplimiento: x.porcentajecumplimiento,
                    objetivo: {
                        descripcion: y.descripcion,
                        id: y.id,
                        porcentajecumplimiento: y.proyectos[0].porcentajecumplimiento
                    },
                    proyecto: {
                        id: x.proyecto.id
                    }
                })
            })
        })

        const tableData = {}
        const uniqueData = sendObjectives.filter(function (object) {
            return tableData.hasOwnProperty(object.objetivo.id) ? false : (tableData[object.objetivo.id] = true)
        })

        var newObject = {
            id: e.data.id,
            descripcion: e.data.descripcion,
            objetivos: uniqueData,
            proyecto: idProjectProp,
        }
        setSelectedDeliverableProp([...selectedDeliverableProp, newObject])
    }

    const onRowUnselect = (e) => {
        const result = selectedDeliverableProp.filter(function (object) {
            var id = object.id
            var idProject = object.proyecto
            return id !== e.data.id || idProject !== e.data.proyecto
        })
        setSelectedDeliverableProp(result)
    }

    const onSelectedDeliverableChange = (e) => {
        setSelectedDeliverable(e.value)
        if (e.type === "all") {
            var filterProject = e.value.map(z => {
                return {
                    actualizadopor: z.actualizadopor,
                    creadopor: z.creadopor,
                    descripcion: z.descripcion,
                    estado: z.estado,
                    fechaactualizado: z.fechaactualizado,
                    fechacreacion: z.fechacreacion,
                    id: z.id,
                    objetivos: z.objetivos.filter(object => object.proyecto.id === parseInt(idProjectProp)),
                    proyecto: z.proyecto,
                }
            })
            var sendData = filterProject.map(item => {
                var objectiveFilter = []
                item.objetivos.forEach(y => {
                    objectivesProp.forEach(x => {
                        if (x.id === y.objetivo.id) {
                            objectiveFilter.push(x)
                        }
                    })
                })
                var objectiveProject = objectiveFilter.map(item => {
                    return {
                        descripcion: item.descripcion,
                        id: item.id,
                        proyectos: item.proyectos.filter(object => object.proyecto.id === parseInt(idProjectProp))
                    }
                })
                var sendObjectives = []
                item.objetivos.forEach(x => {
                    objectiveProject.forEach(y => {
                        sendObjectives.push({
                            id: x.id,
                            fechacumplimiento: x.fechacumplimiento,
                            porcentajecumplimiento: x.porcentajecumplimiento,
                            objetivo: {
                                descripcion: y.descripcion,
                                id: y.id,
                                porcentajecumplimiento: y.proyectos[0].porcentajecumplimiento
                            },
                            proyecto: {
                                id: x.proyecto.id
                            }
                        })
                    })
                })
                const tableData = {}
                const uniqueData = sendObjectives.filter(function (object) {
                    return tableData.hasOwnProperty(object.objetivo.id) ? false : (tableData[object.objetivo.id] = true)
                })
                return {
                    descripcion: item.descripcion,
                    id: item.id,
                    objetivos: uniqueData,
                    proyecto: idProjectProp
                }
            })
            if (e.value.length !== 0) {
                var arrayData = selectedDeliverableProp.concat(sendData)
                var arrayDataMap = arrayData.map(item => {
                    return [JSON.stringify(item), item]
                })
                var arrayDataMapArr = new Map(arrayDataMap)
                var uniqueData = [...arrayDataMapArr.values()]
                setSelectedDeliverableProp(uniqueData)
            } else {
                setDisabledIndexProp(true)
                var newDeliverable = deliverables.map(item => {
                    return {
                        id: item.id,
                        proyecto: idProjectProp
                    }
                })
                var newData = []
                for (var i = 0; i < selectedDeliverableProp.length; i++) {
                    var equal = false;
                    for (var j = 0; j < newDeliverable.length & !equal; j++) {
                        if (selectedDeliverableProp[i]['id'] === newDeliverable[j]['id'] &&
                            selectedDeliverableProp[i]['proyecto'] === newDeliverable[j]['proyecto'])
                            equal = true;
                    }
                    if (!equal) newData.push(selectedDeliverableProp[i]);
                }
                setSelectedDeliverableProp(newData)
            }
        }

        if (e.value.length !== 0) {
            setDisabledIndexProp(true)
        }
    }

    useEffect(() => {
        if (objectMonthlyReportDataProp !== undefined) {
            var arrayDeliverable = JSON.parse(objectMonthlyReportDataProp.entregables)
            var result = arrayDeliverable.filter(function (object) {
                var idProject = object.proyecto
                return String(idProject) === String(idProjectProp)
            })
            setSelectedDeliverable(result)
            setSelectedDeliverableProp(JSON.parse(objectMonthlyReportDataProp.entregables))

        } else {
            setSelectedDeliverable([])
            if (setSelectedDeliverableProp !== undefined) {
                setSelectedDeliverableProp([])
            }
        }
    }, [objectMonthlyReportDataProp, setSelectedDeliverableProp, idProjectProp])

    var permissionsDeliverableCreate
    if (permissionsProp[19].permisos[0] === "Crear") {
        permissionsDeliverableCreate = true
    } else {
        permissionsDeliverableCreate = false
    }
    var permissionsDeliverableUpdate
    if (permissionsProp[19].permisos[1] === "Editar") {
        permissionsDeliverableUpdate = true
    } else {
        permissionsDeliverableUpdate = false
    }
    var permissionsDeliverableDelete
    if (permissionsProp[19].permisos[2] === "Eliminar") {
        permissionsDeliverableDelete = true
    } else {
        permissionsDeliverableDelete = false
    }

    var permissionsColumnActions
    if (permissionsDeliverableDelete === false && permissionsDeliverableUpdate === false) {
        permissionsColumnActions = false
    } else {
        permissionsColumnActions = true
    }

    const acctions = (rowData) => {
        var uniqueFilterDeliverable = []
        if (monthlyReportDataAllDataProp) {
            var monthlyReportDataAllDataPropDeliverable = monthlyReportDataAllDataProp.map(item => {
                return {
                    entregables: JSON.parse(item.entregables)
                }
            })

            var filterDeliverable = []
            const result = monthlyReportDataAllDataPropDeliverable.map(item => {
                return item.entregables.map(j => {
                    var boolean
                    if (j.id === rowData.id) {
                        boolean = item
                    }
                    return boolean
                })

            })
            result.forEach(i => {
                i.forEach(j => {
                    if (j !== undefined) {
                        if (j.length !== 0) {
                            filterDeliverable.push(j)
                        }
                    }
                })
            })
            uniqueFilterDeliverable = [...new Set(filterDeliverable)]
        }

        var noDeleteSDeliverable = false
        var messagDelete = '¿Está seguro de eliminar este entregable?, Recuerde que una vez se elimine, se borrará definitivamente del sistema'
        if (rowData.creadopor !== usuario) {
            noDeleteSDeliverable = true
            messagDelete = 'No puede eliminar registros creados por otro usuario'
        } else if (uniqueFilterDeliverable.length !== 0) {
            noDeleteSDeliverable = true
            messagDelete = 'No puede eliminar este entregable por que ya ha sido usado por otro usuario'
        }

        return (
            <>
                {permissionsDeliverableUpdate ?
                    readReportProp ?
                        null
                        :

                        <BtnActualizarEntregables
                            projectsProp={rowData.objetivos}
                            idDeliverableProp={rowData.id}
                            deliverableProp={rowData.descripcion}
                            creationDateProp={rowData.fechacreacion}
                            createdByProp={rowData.creadopor}
                            idProjectProp={idProjectProp}
                            projectProp={projectProp}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRender}
                            runRenderProp={runRender}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            listDeliverableProp={deliverables}
                            listObjectivesProp={rowData.objetivos}
                            dataDeliverableProp={individualMonthReportProp ? deliverablesProp : deliverables}
                        />
                    :
                    ""
                }

                <Objetivos
                    objectivesProp={rowData.objetivos}
                    idDeliverableProp={rowData.id}
                    deliverableProp={rowData.descripcion}
                    creationDateProp={rowData.fechacreacion}
                    createdByProp={rowData.creadopor}
                    idProjectProp={idProjectProp}
                    projectProp={projectProp}
                    individualMonthReportProp={individualMonthReportProp}
                    setRunRenderProp={setRunRender}
                    runRenderProp={runRender}
                    readReportProp={readReportProp}
                    reloadObjectiveProp={reloadObjectiveProp}
                    deliverablesProp={deliverablesProp}
                    runRenderBlockProp={runRenderBlockProp}
                    setRunRenderBlockProp={setRunRenderBlockProp}
                    listObjectivesProp={objectivesProp}
                    dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                    dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                />

                {permissionsDeliverableDelete ?
                    readReportProp ?
                        null
                        :
                        <ConfirmBtn
                            tittle="Confirmar Eliminación"
                            menssage={messagDelete}
                            action="Delete"
                            label=""
                            classProp="p-button-danger"
                            icon="pi pi-trash"
                            tooltipProp="Eliminar"
                            idDeliverableProp={rowData.id}
                            setRunRenderProp={setRunRender}
                            runRenderProp={runRender}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            noDeleteSDeliverableProp={noDeleteSDeliverable}
                        />
                    :
                    ""
                }

            </>
        )
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    // proceso para exportar datos por Excel


    const exportExcel = () => {

        const newArrWithOutId = deliverablesProp.map(item => {
            const { id, ...rest } = item; // Usamos destructuring para excluir la propiedad 'id'
            return rest;
        })
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(newArrWithOutId);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Datos');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const header = (

        <div className="headerTable">
            {permissionsDeliverableCreate ?
                showAllDataProp ?
                    null
                    :
                    readReportProp ?
                        null
                        :
                        emptyObjectivesProp ?
                            null
                            :
                            newSelectedObjectiveProp?.length !== 0 &&
                            <BtnCrearEntregables
                                idProjectProp={idProjectProp}
                                projectProp={projectProp}
                                getClientsProp={getClientsProp}
                                dateStartValidityProp={dateStartValidityProp}
                                dateEndValidityProp={dateEndValidityProp}
                                individualMonthReportProp={individualMonthReportProp}
                                setRunRenderProp={setRunRender}
                                runRenderProp={runRender}
                                reloadObjectiveProp={reloadObjectiveProp}
                                runRenderBlockProp={runRenderBlockProp}
                                setRunRenderBlockProp={setRunRenderBlockProp}
                                deliverablesProp={deliverablesProp}
                                newDeliverablesProp={deliverables}
                                objectivesProp={objectivesProp}
                                projectsUserSessionProp={projectsUserSessionProp}
                                listDeliverableProp={deliverables}
                            />
                :
                ""
            }
            {loading === true ? <p>Cargando información...</p> : ''}
            {showAllDataProp ?
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLSX" />
                :
                null
            }
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );
    let small = UseMedia("(max-width: 760px)");

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                {showAllDataProp ?
                    <DataTable ref={dt} value={deliverablesProp} dataKey="id" className="p-datatable-responsive-demo" header={header} globalFilter={globalFilter}
                        emptyMessage="Entregable no registrado en el sistema" paginator rows={10} loading={loadingProp} responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px"
                    >                        
                        <Column className="columna" field="cliente" sortable header="Cliente" />
                        <Column className="columna" field="proyecto" sortable header="Proyecto" />
                        <Column className="columna" field="periodo" sortable header="Periodo" />
                        <Column className="columna" field="equipoTrabajo" sortable header="Equipo de Trabajo" />
                        <Column className="columna" field="nombreColaborador" sortable header="Nombre Colaborador" />
                        <Column className="columna" field="objetivo" sortable header="Objetivo" />
                        <Column className="columna" field="entregable" sortable header="Entregable" />
                        <Column className="columna" field="porcentajeCumplimiento" sortable header="%Cumplimiento" />
                        <Column className="columna" field="fechacumplimiento" sortable header="Fecha Cumplimiento" />
                    </DataTable>
                    :
                    <DataTable ref={dt} value={deliverables} dataKey="id" className="p-datatable-responsive-demo" header={header} globalFilter={globalFilter}
                        paginator rows={5} sortField={individualMonthReportProp === true ? "" : "descripcion"} sortOrder={1}
                        loading={individualMonthReportProp ? loadingProp : loading}
                        selection={readReportProp ? null : selectedDeliverable} onSelectionChange={readReportProp ? null : e => onSelectedDeliverableChange(e)}
                        selectionMode={individualMonthReportProp === true ? "checkbox" : null}
                        onRowUnselect={readReportProp ? null : onRowUnselect} onRowSelect={readReportProp ? null : onRowSelect}
                        emptyMessage={selectedObjectiveProp?.length === 0 ? "Debe seleccionar primero un objetivo para este proyecto, luego podrá gestionar los entregables del proyecto" : "Entregable no registrado en el sistema"}
                    >
                        {individualMonthReportProp === true ?
                            showAllDataProp ?
                                null
                                :
                                readReportProp ?
                                    null
                                    :
                                    <Column selectionMode="multiple" style={{ maxWidth: '1rem' }} ></Column>
                            :
                            <></>
                        }
                        <Column className="columna" field="id" sortable header="Id Entregable" />
                        <Column className="columna" field="descripcion" sortable header="Entregable" />
                        {individualMonthReportProp === true ?
                            <Column className="columna" field="creadopor" sortable header="Creado por" />
                            :
                            <></>
                        }
                        {showAllDataProp ?
                            null
                            :
                            permissionsColumnActions ?
                                <Column header="Acciones" body={acctions} />
                                :
                                <Column />
                        }
                    </DataTable>
                }
                {individualMonthReportProp === true && disabledIndexProp === true && loadingProp === false ?
                    <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                        <div style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                                <Message severity="warn" text="Debe guardar los datos para cambiar de pestaña" />
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default ConsultarEntregables
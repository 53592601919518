import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { RadioButton } from 'primereact/radiobutton';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api'
import Services from '../../service/Services';
import '../../assets/css/DataTable.css';
import { /* SumarDias, */ CambiarFormatoFecha, EstandarFormatoFecha } from '../Funciones';
/* import { Dropdown } from 'primereact/dropdown'; */
import { MultiSelect } from "primereact/multiselect";

//integrar servicio

const ConsultarHistorico = () => {

    const [servicio, setServicio] = useState([]);
    const [saveService, setSaveService] = useState([]);
    const [servicesClientesSession, setServicesClientesSession] = useState([]);

    const [loading, setLoading] = useState(true);

    const getServices = new Services();

    const [selectTable, setSelectTable] = useState('selectSemana');

    useEffect(() => {
        setLoading(true)
        const convertDate = (date) => {
            var newDate = new Date(date)
            return newDate
        }

        getServices.getUsuarios().then(data => {
            var userService = data.map(item => {
                return {
                    apellidos: item.apellidos,
                    cargo: item.cargo,
                    comentariobloqueo: item.comentariobloqueo,
                    correo: item.correo,
                    estado: item.estado,
                    fecIngreso: item.fecIngreso,
                    idColaborador: item.idColaborador,
                    idUsuario: item.idUsuario,
                    intentos: item.intentos,
                    nombres: item.nombres,
                    nrodocumento: item.nrodocumento,
                    proyectos: item.proyectos,
                    razonsocial: item.razonsocial,
                    rol: item.rol,
                    tipobloqueo: item.tipobloqueo,
                    tipodocumento: item.tipodocumento,
                    tipousuario: item.tipousuario,
                    ultimafecharegistro: item.ultimafecharegistro,
                    usuario: item.usuario,
                    nombrecompleto: item.nombres + ' ' + item.apellidos
                }
            })
            getServices.getHistorico().then(newData => {
                var dateSplit = []

                newData.forEach(element => {
                    var arr = element.rango.split('-')
                    var newArr = arr.map(item => {
                        return convertDate(item)
                    })
                    var arrFormat = newArr.map(item => {
                        return EstandarFormatoFecha(item)
                    })
                    var arrRAnge = newArr.map(item => {
                        return CambiarFormatoFecha(item)
                    })
                    var arrUnion = arrFormat[0] + " / " + arrFormat[1]

                    var newUser = userService.filter(function (object) {
                        return object.idUsuario === element.idusuario.idUsuario
                    })
                    dateSplit.push({
                        fechaCreacion: element.fechaCreacion,
                        id: element.id,
                        idusuario: newUser[0],
                        numerohoras: element.numerohoras,
                        rango: arrUnion,
                        tipo: element.tipo,
                        dateStart: arrRAnge[0],
                        dateEnd: arrRAnge[1]
                    })
                })

                if (selectTable === 'selectSemana') {
                    dateSplit.sort((a, b) => new Date(b.dateEnd) - new Date(a.dateEnd))
                    setServicio(dateSplit.filter(function (object) {
                        return object.tipo === 'SEMANAL'
                    }))
                    setSaveService(dateSplit.filter(function (object) {
                        return object.tipo === 'SEMANAL'
                    }))
                    setLoading(false)
                } else if (selectTable === 'selectMes') {
                    dateSplit.sort((a, b) => new Date(b.dateEnd) - new Date(a.dateEnd))
                    setServicio(dateSplit.filter(function (object) {
                        return object.tipo === 'MENSUAL' || object.tipo === 'CONS_REPORTE_MENSUAL'
                    }))
                    setSaveService(dateSplit.filter(function (object) {
                        return object.tipo === 'MENSUAL' || object.tipo === 'CONS_REPORTE_MENSUAL'
                    }))
                    setLoading(false)
                }
            });
        })

        getServices.getProyectosUsuarios().then(data => {

            var newData = data.map(item => {
                return item
            })
            const tableClients = {}
            const uniqueClients = newData.filter(function (object) {
                return tableClients.hasOwnProperty(object.cliente.id) ? false : (tableClients[object.cliente.id] = true)
            })
            setServicesClientesSession(uniqueClients.map(item => {
                return {
                    name: item.cliente.nombre,
                    id: parseInt(item.cliente.id)
                }
            }))
        })


    }, [selectTable]); // eslint-disable-line react-hooks/exhaustive-deps

    const [customer, setCustomer] = useState(null)

    const customers = servicesClientesSession.map(item => {
        const av = { customer: item.name, id: item.id}
        return av
    })

    const onCustomerChange = (e) => {
        setCustomer(e.value)
        filterData(dateStart, dateEnd, date, e.value)
    }

    const filterData = (dateStart, dateEnd, date, clients) => {
        
        if (dateStart === null && dateEnd === null && clients !== null && clients.length !== 0 && date === null) {
            setServicio(filterDataClient(clients, saveService))
        } else if (dateStart !== null && dateEnd !== null && (clients === null || clients.length === 0)) {
            setServicio(filterRangeDate(dateStart, dateEnd, saveService))
        } else if (dateStart !== null && dateEnd !== null && clients !== null && clients.length !== 0) {
            var dataClient = filterDataClient(clients, saveService)
            setServicio(filterRangeDate(dateStart, dateEnd, dataClient))
        } else if (dateStart === null && dateEnd === null && (clients === null || clients.length === 0) && date !== null) {
            setServicio(filterDate(date, saveService))
        } else if (dateStart === null && dateEnd === null && clients !== null && clients.length !== 0 && date !== null) {
            var dataClient2 = filterDataClient(clients, saveService)
            setServicio(filterDate(date, dataClient2))
        } else if (dateStart === null && dateEnd === null && (clients === null || clients.length === 0) && date === null) {
            setServicio(saveService)
        }
    }

    const filterDataClient = (clients, data) => {
        var setData = []
        clients.forEach(element => {
            const result = data.map(item => {
                return item.idusuario.proyectos.map(j => {
                    var boolean = undefined
                    if (j.proyecto.cliente.id === parseInt(element.id)) {
                        boolean = item
                    }
                    return boolean
                })
            })
            result.forEach(i => {
                i.forEach(j => {
                    if (j !== undefined) {
                        if (j.length !== 0) {
                            setData.push(j)
                        }
                    }
                })
            })
        })
        const tableData = {}
        const uniqueData = setData.filter(function (object) {
            return tableData.hasOwnProperty(object.id) ? false : (tableData[object.id] = true)
        })
        return uniqueData
    }

    const filterDate = (date, data) => {
        let month = date.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        var newDate = month + '-' + date.getFullYear()
        var sendData = data.filter((object) =>
            object.rango.includes(String(newDate))
        )
        return sendData
    }

    const filterRangeDate = (dateStart, dateEnd, data) => {
        var newDateStart = CambiarFormatoFecha(dateStart)
        var newDateEnd = CambiarFormatoFecha(dateEnd)
        var sendData = data.filter(function (object) {
            return ((newDateStart <= object.dateStart && newDateStart <= object.dateEnd
                && newDateEnd >= object.dateStart && newDateEnd >= object.dateEnd) || 
                (newDateStart >= object.dateStart && newDateStart <= object.dateEnd && newDateEnd >= object.dateStart && newDateEnd >= object.dateEnd))
        })
        return sendData
    }

    const dt = useRef(null);

    const [date, setDate] = useState(null);

    const onConvertirFechaChange = (e) => {
        setDate(e.value)
        filterData(dateStart, dateEnd, e.value, customer)
    }
    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        setDateStart(e.value)
        filterData(e.value, dateEnd, date, customer)
    }
    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        setDateEnd(e.value)
        filterData(dateStart, e.value, date, customer)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const resetData = () => {
        setDate(null)
        setDateStart(null)
        setDateEnd(null)
        setCustomer(null)
    }

    const exportExcel = () => {
        var exportService = servicio.map(item => {
            return {
                funcionario: item.idusuario.nombrecompleto,
                rango: item.rango,
                Horas_Por_Registrar: item.numerohoras
            }
        })
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(exportService);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Datos');
        });
    }

    var dateSelected

    if (date !== null) {
        dateSelected = date.toLocaleString("es-US", { month: "long" }) + '_' + date.getFullYear()
    } else {
        dateSelected = 'Total_datos'
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_Export_Historico_' + dateSelected + EXCEL_EXTENSION);
        });
    }

    var disabledExport = false

    if (servicio === null || servicio.length === 0) {
        disabledExport = true
    }

    var dateError = false
    var dateMessage = ''

    if (date > new Date()) {
        dateError = true
        dateMessage = 'No se puede seleccionar un mes futuro'
    } else {
        dateError = false
        dateMessage = ''
    }

    var dateStartError = false
    var dateStartMessage = ''
    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > new Date() || dateEnd > new Date()) {
            dateStartError = true
            dateStartMessage = 'No puede seleccionar fechas futuras'
        } else if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha de inicio no puede ser mayor a la fecha fin'
        } else {
            dateStartError = false
            dateStartMessage = ''
        }
    }

    var showMessage = false
    if (servicio.length !== 0 && dateStart !== null && dateEnd !== null && dateStartError === false) {
        showMessage = true
    }

    const header = (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label className="labels" >Cliente</label>
                    <MultiSelect value={customer} options={customers} onChange={onCustomerChange} optionLabel="customer" placeholder="Cliente" />
                </div>
                {selectTable === 'selectSemana' ?
                    <>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" >Fecha inicio</label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es" showButtonBar
                                className={dateStartError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" >Fecha fin</label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es" showButtonBar />
                        </div>
                    </>
                    :
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels" >Mes</label>
                        <Calendar placeholder="MM-AAAA a consultar " value={date} onChange={onConvertirFechaChange} view="month" dateFormat="mm-yy" locale="es" showButtonBar
                            className={dateError ? "p-invalid" : ""}
                        />
                        <p className="mensajeError">{dateError ? dateMessage : ""}</p>
                    </div>
                }

                <div className="p-field p-col-12 p-md-3">
                    <label className="labels" >Exportar Histórico</label>
                    <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLSX" disabled={disabledExport} />
                </div>

            </div >
            {showMessage ?
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <span className="text" style={{ fontWeight: '100' }}>{selectTable === 'selectSemana' ?
                            "Rango acumulado de consulta " + servicio[0].rango
                            :
                            ""
                        }</span>
                    </div>
                </div >
                :
                ""
            }

        </>
    );

    return (
        <div className="card">
            <div className="p-field-radiobutton">
                <RadioButton inputId="selectSemana" name="selectSemana" value="selectSemana" onChange={(e) => {
                    setSelectTable(e.value)
                    resetData()
                }} checked={selectTable === 'selectSemana'} />
                <label htmlFor="selectMes">Histórico Semanal Acumulado </label>
            </div>
            <div className="p-field-radiobutton">
                <RadioButton inputId="selectMes" name="selectMes" value="selectMes" onChange={(e) => {
                    setSelectTable(e.value)
                    resetData()
                }} checked={selectTable === 'selectMes'} />
                <label htmlFor="selectMes">Histórico Mensual</label>
            </div>

            <div className="datatable-responsive-demo">
                <div className="card">
                    <DataTable ref={dt} value={servicio} header={header} className="p-datatable-responsive-demo" paginator rows={10}
                        emptyMessage="No hay datos para exportar" sortField="idusuario.nombre" sortOrder={1}
                        loading={loading}
                    >
                        <Column className="columna" field="rango" header="Semanas" />
                        <Column className="columna" field="idusuario.nombrecompleto" sortable header="Funcionarios" />
                        <Column className="columna" field="numerohoras" sortable header="Horas por registrar" />

                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default ConsultarHistorico
import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../../assets/css/DialogDemo.css';
import '../../../assets/css/Forms.css'
import axios from 'axios';

const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/calendar/updateCalendar'
const urlCrear = process.env.REACT_APP_BASE_URL + '/v1/api/calendar/createCalendar'

const ConfirmBtn = ({ paisProp, fechasProp, usuarioProp, idRegistroProp, accion, label, titulo, fechaCreacionProp, creadoPorProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const servicio = () => {
        if (accion === "Crear") {
            crearCalendario()
        } else if (accion === "Actualizar") {
            actualizarCalendario()
        } else if (accion === "Eliminar") {
            eliminarCalendario()
        }
    }

    /* console.log(paisProp, fechasProp,usuarioProp,idRegistroProp) */
    
    const crearCalendario = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                creadopor: usuarioProp,
                fechaactualizado: "",
                fechacreacion: new Date(),
                fechas: fechasProp,
                id: 0,
                nombre: "Caledario de " + paisProp,
                pais: paisProp
            }
        )
        /* console.log(json) */
        await axios.post(urlCrear, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarCalendario"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status !== 200) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'Se ha presentado un problema registrando los datos', life: 4000 });
                }
            })
    }

    const actualizarCalendario = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuarioProp,
                creadopor: creadoPorProp,
                fechaactualizado: new Date(),
                fechacreacion: fechaCreacionProp,
                fechas: fechasProp,
                id: idRegistroProp,
                nombre: "Caledario de " + paisProp,
                pais: paisProp
            }
        )
        /* console.log(json) */

        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarCalendario"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'Calendario existente en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })



    }
    const urlEliminar = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/aplication/delete?id='
    const eliminarCalendario = async () => {
        await axios.delete(urlEliminar,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/aplicaciones"
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus />
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} onClick={() => onClick('displayConfirmation')} className="p-button-outlined p-button-success"
                tooltip={label} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={titulo} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>¿Está seguro de guardar los días festivos para este país?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn
import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import ConfirmBtn from "./ConfirmBtn";
import BtnUpdateDatail from './BtnUpdateDetail';
import { EstandarFormatoFecha, SumarDias, CambiarFormatoFecha } from '../Funciones';
import { RadioButton } from 'primereact/radiobutton';
import axios from 'axios';
import Cookies from 'universal-cookie';

const VerClientesProyectos = ({ objectiveProp, projectsProp, idObjectiveProp, creationDateProp, createdByProp, idProjectProp,
    individualMonthReportProp, setRunRenderProp, runRenderProp, permissionsProp, readReportProp, reloadObjectiveProp, setReloadObjectiveProp,
    runRenderBlockProp, setRunRenderBlockProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp,

     dateStartProp, dateEndProp, runRenderProp2 }) => {

    const cookies = new Cookies();

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');
    const usuario = cookies.get('usuario')


    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const [servicesActivities, setServicesActivities] = useState([])
    const [detailProjects, setDetailProjects] = useState([])

    useEffect(() => {
        var d = new Date();
        var currentDate = new Date(); 
        var gmtHours = -d.getTimezoneOffset() / 60;
        var newProjectProp = projectsProp.map(item => {
            var newDateStart;
            var newDateEnd;
            var newDate;
            
            if (gmtHours < 0) {
                newDateStart = SumarDias(new Date(item.fechainiciovigencia), 1);
                newDateEnd = item.fechafinvigencia ? SumarDias(new Date(item.fechafinvigencia), 1) : null;
                newDate = item.fechacumplimiento ? SumarDias(new Date(item.fechacumplimiento), 1) : null;
            } else {
                newDateStart = new Date(item.fechainiciovigencia);
                newDateEnd = item.fechafinvigencia ? new Date(item.fechafinvigencia) : null;
                newDate = item.fechacumplimiento ? new Date(item.fechacumplimiento) : null;
            }
    
            var status;
            if (item.estado === 1 && (newDateEnd === null || newDateEnd >= currentDate)) {
                status = "Vigente";
            } else {
                status = "No vigente";
            }
    
            return {
                estado: status,
                fechacreacion: item.fechacreacion,
                fechafinvigencia: newDateEnd ? EstandarFormatoFecha(newDateEnd) : null,
                fechainiciovigencia: EstandarFormatoFecha(newDateStart),
                fechacumplimiento: newDate ? EstandarFormatoFecha(newDate) : null,
                sendDateStart: item.fechainiciovigencia,
                sendDateEnd: item.fechafinvigencia,
                sendDate: item.fechacumplimiento,
                id: item.id,
                porcentajecumplimiento: item.porcentajecumplimiento,
                proyecto: item.proyecto
            };
        });
    
        if (individualMonthReportProp === true) {
            var newData = newProjectProp.filter(object => {
                var id = object.proyecto.id;
                return id === parseInt(idProjectProp);
            });
            setDetailProjects(newData);
        } else {
            setDetailProjects(newProjectProp);
        }
    }, [projectsProp, idProjectProp, individualMonthReportProp, runRenderProp]);
    
    useEffect(() => {
        if (individualMonthReportProp === true && displayBasic===true) {

            const executeServices = async () => {

                const newDateStart = CambiarFormatoFecha(dateStartMonthlyReportProp)
                const newDateEnd = CambiarFormatoFecha(dateEndMonthlyReportProp)
                const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
                const json = JSON.stringify({
                    esadicional: "0",
                    fecFinal: newDateEnd,
                    fecInicial: newDateStart,
                    usuario: usuario
                })


                try {

                    const response = await axios.post(url, json,
                        {
                            headers:
                            {
                                'Content-Type': 'application/json',
                                "Access-Control-Allow-Origin": "*",
                                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                            },
                        })
                    const filter = response.data.filter(item => {
                        return item.idObjetivo === idObjectiveProp
                    })

                    const newData = await Promise.all(filter.map(async (item) => {
                        let newDate;
                        const gmtHours = -new Date().getTimezoneOffset() / 60;
                        if (gmtHours < 0) {
                            newDate = SumarDias(new Date(item.fecha), 1);
                        } else {
                            newDate = new Date(item.fecha);
                        }

                        return {
                            ...item,
                            fecha: EstandarFormatoFecha(newDate),
                            sendDate: item.fecha,
                        };
                    }));
                    setServicesActivities(newData)

                }
                catch (error) {

                }
            }

            if (dateStartMonthlyReportProp !== null && dateEndMonthlyReportProp !== null) {
                executeServices()
            }
        }


    }, [displayBasic,idObjectiveProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp, runRenderProp2, individualMonthReportProp])// eslint-disable-line react-hooks/exhaustive-deps


    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    var permissionsObjectiveUpdate
    if (permissionsProp[17].permisos[1] === "Editar") {
        permissionsObjectiveUpdate = true
    } else {
        permissionsObjectiveUpdate = false
    }
    var permissionsObjectiveDelete
    if (permissionsProp[17].permisos[2] === "Eliminar") {
        permissionsObjectiveDelete = true
    } else {
        permissionsObjectiveDelete = false
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const header = (
        <div className="headerTable">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                    />
                </span>
            </div>
        </div>
    );

    var dontDelete = false
    var dontDeleteMessage = ''
    if (detailProjects.length === 1) {
        dontDelete = true
        dontDeleteMessage = 'Este objetivo tiene una asociación debe asociar otro proyecto para poder eliminar este'
    } else {
        dontDelete = false
        dontDeleteMessage = ''
    }
    const acctions = (rowData) => {
        /* console.log(rowData) */
        return (
            <>
                {permissionsObjectiveUpdate ?
                    readReportProp ?
                        null
                        :
                        <BtnUpdateDatail
                            objectiveProp={objectiveProp}
                            idObjectiveProp={idObjectiveProp}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            projectProp={rowData.proyecto}
                            clientProp={rowData.proyecto.cliente}
                            dateStartValidityProp={rowData.sendDateStart}
                            dateEndValidityProp={rowData.sendDateEnd}
                            dateProp={rowData.sendDate}
                            statusProp={rowData.estado}
                            percentageProp={rowData.porcentajecumplimiento}
                            projectObjectiveProp={rowData.id}
                            projectObjectiveCreationDateProp={rowData.fechacreacion}
                            projectsProp={projectsProp}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            updateDatailProp={true}
                            reloadObjectiveProp={reloadObjectiveProp}
                            setReloadObjectiveProp={setReloadObjectiveProp}
                            dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                            dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                        />
                    :
                    ""
                }
                {permissionsObjectiveDelete ?
                    readReportProp ?
                        null
                        :
                        <ConfirmBtn
                            tittle={dontDelete ? "Advertencia" : "Confirmar Eliminación"}
                            menssage={dontDelete ? dontDeleteMessage : "¿Está seguro de eliminar esta asociación?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"}
                            action="Update"
                            label=""
                            classProp="p-button-danger"
                            icon="pi pi-trash"
                            tooltipProp="Eliminar"
                            objectiveProp={objectiveProp}
                            idObjectiveProp={idObjectiveProp}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            idProjectObjective={rowData.id}
                            statusProp={3}
                            dateStartProp={rowData.sendDateStart}
                            dateEndProp={rowData.sendDateEnd}
                            idProyectProp={rowData.proyecto.id}
                            assingnedNewProjectObjective={false}
                            percentageProp={rowData.porcentajecumplimiento}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            updateDatailProp={true}
                            deleteUpdateProp={true}
                            reloadObjectiveProp={reloadObjectiveProp}
                            setReloadObjectiveProp={setReloadObjectiveProp}
                            dontDeleteProp={dontDelete}
                        />
                    :
                    ""
                }
            </>
        )
    }

    const [selectedMode, setSelectedMode] = useState('Asociacionclienteproyecto');

    const onselectedModeChange = (e) => {
        setSelectedMode(e.value)
    }

    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Asociaciones" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                {
                    individualMonthReportProp ?
                        <div className="p-fluid p-formgrid p-grid">
                            <div style={{ display: 'contents' }} >
                                <div className="p-field p-col-12 p-md-3">
                                    <div className="p-field-radiobutton" style={{ marginBottom: '5px' }}>
                                        <RadioButton inputId="Asociacionclienteproyecto" name="Asociacionclienteproyecto" value="Asociacionclienteproyecto" onChange={onselectedModeChange} checked={selectedMode === 'Asociacionclienteproyecto'} />
                                        <label htmlFor="Asociacionclienteproyecto" style={{ fontWeight: '100' }}>Asociación Cliente Proyecto</label>
                                    </div>
                                    <div className="p-field-radiobutton" style={{ marginTop: '10px' }}>
                                        <RadioButton inputId="AsociacionActividades" name="AsociacionActividades" value="AsociacionActividades" onChange={onselectedModeChange} checked={selectedMode === 'AsociacionActividades'} />
                                        <label htmlFor="AsociacionActividades" style={{ fontWeight: '100' }}>Asociación de Actividades</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                }

                <div className="datatable-responsive-demo">
                    <div className="card">
                        {
                            selectedMode === 'Asociacionclienteproyecto' ?
                                <DataTable ref={dt} value={detailProjects} className="p-datatable-responsive-demo" paginator rows={10}
                                    emptyMessage="No hay asociaciones registradas para este objetivo" header={header} globalFilter={globalFilter}>
                                    <Column className="columna" field="proyecto.cliente.razonsocial" header="Cliente" />
                                    <Column className="columna" field="proyecto.nombreproyecto" header="Proyecto" />
                                    <Column className="columna" field="fechainiciovigencia" header="Fecha inicio vigencia" />
                                    <Column className="columna" field="fechafinvigencia" header="Fecha fin vigencia" />
                                    <Column className="columna" field="estado" header="Estado" />
                                    <Column className="columna" field="fechacumplimiento" header="Fecha cumplimiento" />
                                    <Column className="columna" field="porcentajecumplimiento" header="Porcentaje cumplimiento" />
                                    <Column header="Acciones" body={acctions} />
                                </DataTable>
                                :
                                <DataTable ref={dt} value={servicesActivities} className="p-datatable-responsive-demo" paginator rows={10}
                                    emptyMessage="No hay asociaciones registradas para este objetivo" header={header} globalFilter={globalFilter}>
                                    <Column className="columna" field="fecha" header="Fecha" />
                                    <Column className="columna" field="cliente.razonsocial" header="Cliente" />
                                    <Column className="columna" field="proyecto.nombreproyecto" header="Proyecto" />
                                    <Column className="columna" field="numerohoras" header="Numero Horas" />
                                    <Column className="columna" field="descripcionactividad" header="Descripcion Actividad" />

                                </DataTable>
                        }

                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default VerClientesProyectos